import CordinatorMain from './CordinatorMain.vue'
import QuestionList from './question_bank/QuestionList.vue'
import QuestionBank from './question_bank/QuestionBank.vue'
import questionReport from '../latradts_report/questionReport.vue'

export default [
    {
        path: '/latradts',
        name:'CordinatorMain',
        component: CordinatorMain,
        meta: {
            requiresAuth: true,
        },

        children:[
            {
                path:'questionReport',
                name:'',
                component:questionReport,
                meta: {
                    permissions: ['can_view_question_bank']
                }
            },
            {
                path:'question_bank',
                name:'',
                component:QuestionBank,
                meta: {
                    permissions: ['can_view_question_bank']
                }
            },
            {
                path:'questions',
                name:'QuestionsList',
                component:QuestionList,
                meta: {
                    permissions: ['can_view_question_bank']
                }
            }
        ]
     },    
]