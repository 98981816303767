<template>
    <v-container fluid>
        <v-app-bar app color="#EB8822" dark dense>
            <v-app-bar-nav-icon @click="mini = !mini"></v-app-bar-nav-icon>
          <router-link to="/">
            <div  class="d-flex align-center" style="background:white;padding:5px 10px 5px 20px;border-radius:40px" >
              <v-img
                  alt="Vuetify Logo"
                  class="shrink mr-2"
                  contain
                  src="/latra.png"
                  transition="scale-transition"
                  width="70"
              />

            </div>
          </router-link>

            <v-spacer></v-spacer>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span> 
                        {{user.firstName}} {{user.lastName}}
                        <v-icon  left
                        v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                    </span>
                </template>
                <v-list>
                    <v-list-item link><v-list-item-title  > <v-icon left>mdi-lock</v-icon> Change Password</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logoutUser"><v-list-item-title  > <v-icon left>mdi-logout</v-icon> Logout</v-list-item-title></v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar> 
        <SideBar :mini="mini"/>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import SideBar from './SideBar.vue'

export default {
    components:{
        SideBar
    },
    data(){
        return{
            mini:true,
            items: [
                { title: 'My Profile',icon:'mdi-account',click:'' },
                { title: 'Settings',icon:'mdi-cog', click:'' },
                { title: 'Logout',icon:'mdi-logout', click:'logoutUser'},
                { title: 'Change Password',icon:'mdi-lock',click:'' },
            ],
            // userdata:Getters.state.user  
        }
    },
   methods: {
    ...mapActions(['logOut']),
    logoutUser: function () {
      

      this.logOut()
        .then(() => this.$router.push('/login'))
    }
  }
,computed: {
    ...mapGetters({authStatus:'authStatus', user:'user'})
  }
}
</script>

<style>

</style>