<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-card class="elevation-12 d-flex justify-center rounded-xl" min-width="200">
                <v-row>
                  <v-col cols="12" md="6" sm="6" xs="12">
                    <div class="rounded-xl d-flex justify-center" style="background:#efefef;height: 100%" >
                      <v-img max-height="350" max-width="350" src="/latra_dts_login.png"></v-img>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" xs="12">
                    <div  class="d-flex align-items-center justify-center mb-5">
                      <v-form class="pt-5 mx-9 " @submit.prevent="loginUser">
                        <div class="text-h5">
                          Login
                        </div>
                        <v-text-field
                            v-model="authDetails.username"
                            class="rounded-lg mt-2"
                            dense
                            label="Email"
                            name="username"
                            outlined
                            prepend-inner-icon="mdi-email"
                            type="text"
                        ></v-text-field>
                        <v-text-field
                            id="password"
                            v-model="authDetails.password"
                            class="rounded-lg"
                            dense
                            label="Password"
                            name="password"
                            outlined
                            prepend-inner-icon="mdi-key"
                            type="password"
                            v-on:keyup.enter="loginUser"
                        ></v-text-field>
                        <div class="d-flex justify-end">
                          <v-btn class="white--text rounded-lg mb-3 pa-5 w-100" color="#EB8822" @click="loginUser" ><v-icon small>mdi-lock-open-outline</v-icon> Login</v-btn>
                        </div>
                      </v-form>
                    </div>
                  </v-col>
                </v-row>

              </v-card>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>
<style>

</style>
<script>
import {mapActions} from 'vuex'
import Vue from "vue";

export default {
   name: 'Login',
   props: {
      source: String,
   },
   data () {
    return {
      authDetails: {
        username: '',
        password: ''
      }

    }
   },
   methods: {
    ...mapActions(['login']),
   async loginUser () {
     if(this.authDetails.username===""||this.authDetails.password===""){
       Vue.swal({
         toast:true,
         position:'top',
         title:'Empty Fields',
         text:'Please make sure both e-mail and password fields are not empty and try again.',
         timerProgressBar:true,
         timer:10000,
         icon:'error',
         showConfirmButton:false,
         animation:false,
       });
     }else {
       this.login(this.authDetails)
     }
    }
  },mounted() {
     localStorage.clear()
  }
};
</script>

<style>
.hidden-sm-and-down .v-icon {
  color: orange !important;
}
</style>
