var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"permanent":"","app":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('v-btn',{staticStyle:{"margin":"5px"},attrs:{"right":"","fab":"","x-small":"","color":"indigo white--text"}},[_c('v-icon',[_vm._v("mdi-cog-outline")])],1),_c('v-btn',{staticStyle:{"margin":"5px"},attrs:{"right":"","fab":"","x-small":"","color":"blue white--text"}},[_c('v-icon',[_vm._v("mdi-account-tie")])],1),_c('v-btn',{staticStyle:{"margin":"5px"},attrs:{"right":"","fab":"","x-small":"","color":"pink white--text"}},[_c('v-icon',[_vm._v("mdi-power")])],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"link":"","to":"/","src":"/arm.png"}})],1),_c('v-list-item-title',{attrs:{"link":"","to":"/"}},[_vm._v(_vm._s(_vm.$store.state.AuthStore.user.firstName)+" "+_vm._s(_vm.$store.state.AuthStore.user.lastName))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_examination_sections';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/examination_sections"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Examination Sections")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_examination_settings';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/examination_settings"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-script-text-play")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Examination Settings")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_examination_categories';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/examination"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shape")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Examination Categories")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_timetable_sessions';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/timetable"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-history")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Timetable Sessions")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_booking_settings';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/booking"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-book")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Booking Settings")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_timetable_sessions';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/venues"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Test Center Managements")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_user';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/users"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Users Management")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_fingerprint_device';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/fingerprint_device_configuration"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-fingerprint")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fingerprint Configuration")])],1)],1):_vm._e(),_c('v-list-group',{attrs:{"value":true,"prepend-icon":"mdi-text-box-multiple"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Report")])]},proxy:true}])},[_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/resultreport"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-line")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Performance Report")])],1)],1),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_session_report';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/sessionReport"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-selection")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Session Report")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_test_center_report';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/testCenterReport"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-map-marker-radius")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Test Center Report")])],1)],1):_vm._e(),(_vm.$store.getters.permissionMap.find(function (ref){
	var permissionDescriptions = ref.permissionDescriptions;

	return permissionDescriptions==='can_view_candidate_report';
})!==undefined)?_c('v-list-item',{attrs:{"link":"","to":"/latradts_settings/candidateReport"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Candidate Report")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }