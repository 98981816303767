<template>
<v-container fluid>
 <v-card>
    <v-toolbar
      color="#eee"
      
    >

        <v-tabs
          v-model="tabs"
          centered
          color="#ef8934"
        >
          <v-tab> <v-icon>mdi-cogs</v-icon>
              Booking Management</v-tab>
          <v-tab>
              <v-icon>mdi-cancel</v-icon>
              Cancelation Limit</v-tab>
        </v-tabs>
    </v-toolbar>

    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <EditBookingSetting :EditBookingSettingDialog="EditBookingSettingDialog" @close="EditBookingSettingDialog=false" :BookingSettingsItems="bookingSettingsItems"/>
            <CreateBookingSetting :CreateBookingSettingDialog="CreateBookingSettingDialog" @close="CreateBookingSettingDialog=false"/>
            <CreateBookingCancelationLimit :CreateBookingCancelationLimitDialog="CreateBookingCancelationLimitDialog" @close="CreateBookingCancelationLimitDialog=false"/>
            <EditBookingCancelationLimit :EditBookingCancelationLimitDialog="EditBookingCancelationLimitDialog" :CancelationLimitItems="cancelationLimitItems" @close="EditBookingCancelationLimitDialog=false"/>
            <v-row>
                <v-tooltip bottom color="#EB8822">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="CreateBookingSettingDialog =true"  v-bind="attrs" v-on="on"  right dark color="#EB8822 " class="rounded-lg ml-5" style="margin:5px">
                            <v-icon class="white--text" small>mdi-plus</v-icon> Create Booking Setting
                            </v-btn>
                    </template>
                    <span>Create Booking Setting</span>
                </v-tooltip>
            </v-row>
            <v-row>
                <v-col sm="12"> 
                    <div style="padding:10px">
                        <template>
                            <v-card>
                                <v-card-title>
                                  Booking Settings
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Search"
                                      single-line
                                      hide-details
                                  ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                :headers="headers"
                                :items="$store.state.BookingSettingsStore.BookingSettings"
                                :search="search"
                                >
                                <template v-slot:item.unique_id="{ item }">
                                    <v-btn fab small text @click="openDialog(item)">
                                        <v-icon color="orange" small>mdi-pen</v-icon>
                                    </v-btn>
                                </template>
                                </v-data-table>
                            </v-card>
                        </template>
                    </div>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-tooltip bottom color="#EB8822">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="CreateBookingCancelationLimitDialog =true"  v-bind="attrs" v-on="on"  right dark color="#EB8822 " class="rounded-lg ml-5" style="margin:5px">
                            <v-icon class="white--text" small>mdi-plus</v-icon> Create Cancelation Limit
                            </v-btn>
                    </template>
                    <span>Create Cancelation Limit</span>
                </v-tooltip>
                </v-row>
            <v-row>
                <v-col sm="12"> 
                    <div style="padding:10px">  
                <template>
                  <v-card>
                      <v-card-title>
                        Cancelation Limit
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                      :headers="headersCancel"
                      :items="$store.state.CancellationLimitStore.cancellationLimits"
                      :search="search"
                      >
                      <template v-slot:item.action="{ item }">
                          <v-btn fab small text @click="openEditDialog(item)">
                              <v-icon color="orange" small>mdi-pen</v-icon>
                          </v-btn>
                      </template>
                      </v-data-table>
                  </v-card>
              </template>
           </div>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
        
</v-container>
</template>

<script>
import EditBookingSetting from './EditBookingSetting.vue'
import CreateBookingSetting from './CreateBookingSetting.vue'
import CreateBookingCancelationLimit from './CreateBookingCancelationLimit.vue'
import EditBookingCancelationLimit from './EditBookingCancelationLimit.vue'
import allBookingSettings from '.././../../../graphql/allBookingSettings.gql'

export default {
data:()=>({
    EditBookingSettingDialog:false,
    CreateBookingSettingDialog:false,
    CreateBookingCancelationLimitDialog:false,
    EditBookingCancelationLimitDialog:false,
    cancelationLimitItems:null,
    bookingSettingsItems:null,
    search: '',
    tabs: null,
    headers: [
        {
        text: 'Maximum Booking Time',
        value: 'max_book_time',
        },
        { text: 'Maximum Cancelation Time', value: 'max_cancel_time' },
         { text: 'Actions', value: 'unique_id' },
        

    ],
    headersCancel: [
        {
        text: 'Cancellation limit',
        value: 'limit_number',
        },
         { text: 'Actions', value: 'action' },
        

    ],
    bookingSettings: [], 
    cancelationLimit: [], 
}),
components:{
    EditBookingSetting,
    CreateBookingSetting,
    CreateBookingCancelationLimit,
    EditBookingCancelationLimit,
},
mounted(){
    this.$store.dispatch('LoadBookingSettings')
    this.$apollo.query({
        query:allBookingSettings,
    }).then((response)=>{
        console.log("Examination Settings")
        console.log(response.data.allBookingSettings.edges)
        response.data.allBookingSettings.edges.forEach(element=>{
            this.bookingSettings.push({'max_book_time':element.node.settingsMaximumBookingTime, 'max_cancel_time':element.node.settingsMaximumCancelationTime,'unique_id':element.node.settingsUniqueId})
        })
    })
    this.$store.dispatch('LoadCancellationLimits')
    // this.$apollo.query({
    //     query:allCancelationLimits,
    // }).then((response)=>{
    //     console.log("Cancelation")
    //     console.log(response.data.allCancelationLimits.edges)
    //     response.data.allCancelationLimits.edges.forEach(element=>{
    //         this.cancelationLimit.push({'limit_number':element.node.limitNumber,'action':''})
    //     })
    // })
},methods:{
    openDialog:function(value){
        console.log(value)
        this.bookingSettingsItems=value
        this.EditBookingSettingDialog=true
    },
    openEditDialog:function(value){
        console.log(value)
        this.cancelationLimitItems=value
        this.EditBookingCancelationLimitDialog=true
    }
}
}
</script>

<style>

</style>