

export default {
    state: {
        permissionRequests:[]
    },
    mutations: {
        AddCandidatePermissionRequests(state,data){permission
            console.log(state,data)
            if(!state.permissionRequests.find(({bookingNumber})=>bookingNumber === data.bookingNumber)){
                state.permissionRequests.push(data)
            }
        },
        removeCandidatePermissionRequest(state,data){
            state.permissionRequests.splice(state.permissionRequests.find(({bookingNumber})=>bookingNumber === data.bookingNumber),1)
        }
    }
}