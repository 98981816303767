import {apolloClient} from '@/vue-apollo'
import allExaminations from '../../graphql/Examinations/allExaminations.gql'
import createExamination from "../../graphql/Examinations/createExamination.gql";
import editExamination from "../../graphql/Examinations/editExamination.gql";
import Vue from "vue";

export default {
    state: {
        allExaminations:[],
        examinationSection:[]
    },
    mutations: {
        addExaminations(state, data){
            state.allExaminations.push(data)
        },
        editExaminations(state, data) {
            state.allExaminations.find(({key}) => key === data.key).title = data.title
            state.allExaminations.find(({key}) => key === data.key).unique_id = data.unique_id
            state.allExaminations.find(({key}) => key === data.key).examinationSetting = data.examinationSetting
        },
        clearExaminations(state){
            state.allExaminations=[]
        },
        addExaminationSection(state, data){
            state.examinationSection.push(data)
        },
        clearExaminationSection(state){
            state.examinationSection=[]
        }
    },
    actions: {
        async LoadExaminations(context){
            context.commit('clearExaminations')

            apolloClient.query({
                query:allExaminations
            }).then((response)=>{
                response.data.allExaminations.edges.forEach(element=>{
                    let data={
                        key:element.node.primaryKey,
                        unique_id:element.node.examinationUniqueId,
                        title:element.node.examinationTitle,
                        examinationSettingKey:element.node.examinationSetting.primaryKey,
                        examinationSetting:element.node.examinationSetting.settingName,
                    }
                    context.commit('addExaminations',data)
                })

            })
        },
        async createExamination(context,data){
            console.log(data)
            apolloClient.mutate({
                mutation:createExamination,
                variables:data
            }).then((response)=>{

                console.log(response.data.createExamination.examination)

                let data={
                    key:response.data.createExamination.examination.primaryKey,
                    unique_id:response.data.createExamination.examination.examinationUniqueId,
                    title:response.data.createExamination.examination.examinationTitle,
                    examinationSetting:response.data.createExamination.examination.examinationSetting.settingName,
                    examinationSettingKey:response.data.createExamination.examination.examinationSetting.primaryKey,
                }
                context.commit('addExaminations',data)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Examination Category created Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! An error Occurred',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                    
                }

            })
        },
        async editExamination(context,data){
            console.log("Hii ni Action")
            console.log(data)
            apolloClient.mutate({
                mutation:editExamination,
                variables:data
            }).then((response)=>{
                console.log(response.data.editExamination.examination)
                let data={
                    key:response.data.editExamination.examination.primaryKey,
                    unique_id:response.data.editExamination.examination.examinationUniqueId,
                    title:response.data.editExamination.examination.examinationTitle,
                    examinationSetting:response.data.editExamination.examination.examinationSetting.settingName,
                }
                context.commit('editExaminations',data)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Examination Updated Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! An error Occurred',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },
    }
}