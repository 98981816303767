<template>
  <v-row justify="center">
    <v-dialog
      v-model="EditExaminationDialog"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Edit Category Examination</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                   <v-text-field
                        v-model="examinationName"
                        label="Examination Category Title *"
                        required
                        prepend-icon="mdi-card-account-details"
                    >
                    </v-text-field>
                    <v-select
                        v-model="examinationSetting"
                        :items="$store.state.ExaminationSettingsStore.allExaminationSettings"
                        chips
                        item-text="name"
                        item-value="key"
                        label="Examination Setting *"
                        prepend-icon="mdi-card-account-details"
                        required
                    >
                    </v-select>
                    </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="#EB8822"
            fab
            small
            class="white--text"
           @click="EditExamination"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

  export default {
    components:{
        
    },
    data: () => ({
      checkbox:false,
      examinationName:null,
      examinationSetting:null,
      examinationUniqueId:null,
    }),
    props: ["EditExaminationDialog","ExaminationItems"],
    mounted(){
      
    },
    methods:{

      EditExamination: function(){
        let data={
          examinationTitle:this.examinationName,
          examinationSetting:this.examinationSetting,
          examinationUniqueId:this.examinationUniqueId
        }
        this.$store.dispatch('editExamination',data).then(()=>{
          this.$emit('close')
        })
        // this.$apollo.mutate({
        //   mutation:editExamination,
        //   variables:{
        //     examinationTitle:this.examinationName,
        //     examinationUniqueId:this.examinationUniqueId
        // }
        // }).then(()=>{
        //   // console.log(response.editExamination.newSettings)
        //   this.$emit('close')
        // })
      }
      
    },watch:{
      EditExaminationDialog:function(){
        this.examinationName=this.ExaminationItems.title
        this.examinationUniqueId=this.ExaminationItems.unique_id
        this.examinationSetting=this.ExaminationItems.examinationSettingKey
      }
    }
  }
</script>