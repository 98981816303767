<template>
    <v-card >
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant.sync="mini"
            permanent
            app
        >
            <v-list-item class="px-2">
            <v-list-item-avatar>
                <v-img  link to="/" src="/arm.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-title link to="/">{{$store.state.AuthStore.user.firstName}} {{$store.state.AuthStore.user.lastName}}</v-list-item-title>

            <v-btn
                icon
                @click.stop="mini = !mini"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            </v-list-item>

            <v-divider></v-divider>
           
            <v-list dense>
              <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_examination_sections')!==undefined" link to="/latradts_settings/examination_sections" >
                  <v-list-item-icon>
                  <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                  <v-list-item-title>Examination Sections</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_examination_settings')!==undefined" link to="/latradts_settings/examination_settings" >
                <v-list-item-icon>
                  <v-icon>mdi-script-text-play</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Examination Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_examination_categories')!==undefined" link to="/latradts_settings/examination" >
                <v-list-item-icon>
                  <v-icon>mdi-shape</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Examination Categories</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_timetable_sessions')!==undefined" link to="/latradts_settings/timetable" >
                <v-list-item-icon>
                  <v-icon>mdi-history</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Timetable Sessions</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_booking_settings')!==undefined" link to="/latradts_settings/booking" >
                <v-list-item-icon>
                  <v-icon>mdi-book</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Booking Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_timetable_sessions')!==undefined" link to="/latradts_settings/venues" >
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Test Center Managements</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_user')!==undefined" link to="/latradts_settings/users" >
                <v-list-item-icon>
                  <v-icon>mdi-account-group-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Users Management</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_fingerprint_device')!==undefined" link to="/latradts_settings/fingerprint_device_configuration" >
                <v-list-item-icon>
                  <v-icon>mdi-fingerprint</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Fingerprint Configuration</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
                <v-list-group
                :value="true"
                prepend-icon="mdi-text-box-multiple"
            >
             <template v-slot:activator>
                <v-list-item-title>Report</v-list-item-title>
                </template>
                
                 <v-list-item link to="/latradts_settings/resultreport" >
                  <v-list-item-icon>
                  <v-icon>mdi-chart-line</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                  <v-list-item-title>Performance Report</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

                 <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_session_report')!==undefined" link to="/latradts_settings/sessionReport" >
                  <v-list-item-icon>
                  <v-icon>mdi-selection</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                  <v-list-item-title>Session Report</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

               <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_test_center_report')!==undefined" link to="/latradts_settings/testCenterReport" >
                  <v-list-item-icon>
                  <v-icon>mdi-map-marker-radius</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                  <v-list-item-title>Test Center Report</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
               
                   <v-list-item v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_candidate_report')!==undefined" link to="/latradts_settings/candidateReport" >
                  <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                  <v-list-item-title>Candidate Report</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
            </v-list-group>
            </v-list>
                <template v-slot:append>
                    <div class="pa-2">
                    <v-btn    right fab x-small color="indigo white--text" style="margin:5px"><v-icon>mdi-cog-outline</v-icon></v-btn>
                    <v-btn   right fab x-small color="blue white--text" style="margin:5px"><v-icon>mdi-account-tie</v-icon></v-btn>
                    <v-btn    right fab x-small color="pink white--text" style="margin:5px"><v-icon>mdi-power</v-icon></v-btn>
                    </div>
                </template>
        </v-navigation-drawer>
    </v-card>  
</template>

<script>
export default {
    props:['mini'],
    data(){
        return{
            drawer:true,

    links:[
      {
        name:'Examination Categories',
        icon:'mdi-shape',
        color:'orange',
        routinglink:'/latradts_settings/examination',
      },
        {
            name:'Timetable Sessions',
            icon:'mdi-history',
            routinglink:'/latradts_settings/timetable',
            permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_examination_settings')!==undefined
        },
         {
            name:'Booking Management',
            icon:'mdi-book',
            routinglink:'/latradts_settings/booking',
            permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_examination_settings')!==undefined
        },
        {
            name:'Venues Managements',
            icon:'mdi-home',
            routinglink:'/latradts_settings/venues',
            permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_take_exam')!==undefined
        },
        {
            name:'Users Management',
            icon:'mdi-account-group-outline',
            routinglink:'/latradts_settings/users',
            permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_take_exam')!==undefined
        },
        {
            name:'Fingerprint Configuration',
            icon:'mdi-fingerprint',
            color:'orange',
            routinglink:'/latradts_settings/fingerprint_device_configuration',
            permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_take_exam')!==undefined
        },
           {
            name:'sessionReport',
            icon:'mdi-fingerprint',
            color:'orange',
            routinglink:'/latradts_report/sessionReport',
            permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_take_exam')!==undefined
        },
        
         
    ],
        }
    }

}
</script>

<style>

</style>