import { apolloClient } from "@/vue-apollo";
import Vue from "vue";
import allUserRoles from "../graphql/allUserRoles.gql";
import activeUserRole from "../graphql/activateUserRole.graphql";

export default {
  state: {
    allUserRoles: [],
  },
  mutations: {
    AddUserRoles(state, data) {
      state.allUserRoles.push(data);
    },
    editRoleActiveStatus(state, data) {
      if (
        state.allUserRoles.find(
          ({ roleUniqueId }) => roleUniqueId === data.roleUniqueId
        )
      ) {
        state.allUserRoles.find(
          ({ roleUniqueId }) => roleUniqueId === data.roleUniqueId
        ).node.roleIsActive = data.roleIsActive;
      }
    },
    clearRoles(state) {
      state.allUserRoles = [];
    },
  },
  actions: {
    async LoadUserRoles(context) {
      context.commit("clearRoles");

      apolloClient
        .query({
          query: allUserRoles,
        })
        .then((response) => {
          let data = response.data.allRoles.edges;
          context.commit("AddUserRoles", data);
        });
    },
    async EditRoleActiveStatusAdmin(context, data) {
      apolloClient
        .mutate({
          mutation: activeUserRole,
          variables: data,
        })
        .then((response) => {
          if (response.data.activateUserRole.success) {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "Role Active status updated Successfully!",
              timerProgressBar: true,
              timer: 4000,
              icon: "success",
              showConfirmButton: false,
              animation: false,
            });
          } else {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "Opps! Looks like an error Occured",
              text: response.errors[0].message,
              timerProgressBar: true,
              timer: 4000,
              icon: "error",
              showConfirmButton: false,
              animation: false,
            });
          }
          response.data.updateUserActiveStatus.user;
          let dataInside = {
            roleUniqueId: response.data.activateUserRole.userRole.roleUniqueId,
            roleIsActive: response.data.activateUserRole.userRole.roleIsActive,
          };
          context.commit("editActiveStatus", dataInside);
        });
    },
  },
};
