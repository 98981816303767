<template>
<v-container fluid>
<!--        <CreateExamination :CreateExaminationDialog="CreateExaminationDialog" @close="CreateExaminationDialog=false"/>-->
        <EditExamination :EditExaminationDialog="EditExaminationDialog" @close="EditExaminationDialog=false" :ExaminationItems="examinationItems"/>
<!--    <v-row>-->
<!--        <v-tooltip bottom color="#EB8822">-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn @click="CreateExaminationDialog =true"  v-bind="attrs" v-on="on"  right dark color="#EB8822 " class="rounded-lg ml-5" style="margin:5px">-->
<!--                    <v-icon class="white&#45;&#45;text" small>mdi-plus</v-icon>  Create Examination Category-->
<!--                    </v-btn>-->
<!--            </template>-->
<!--            <span>Create Examination Category</span>-->
<!--        </v-tooltip>-->
<!--    </v-row>-->
    <v-row>
        <v-col sm="12"> 
            <div style="padding:10px">
                <template>
                    <v-card>
                        <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :items="$store.state.ExaminationStore.allExaminations"
                        :search="search"
                        >
                        <template v-slot:item.unique_id="{ item }">
                            <v-btn fab small text @click="openDialog(item)">
                                <v-icon color="orange" small>mdi-pen</v-icon>
                            </v-btn>
                        </template>
                        </v-data-table>
                    </v-card>
                </template>
            </div>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import EditExamination from './EditExamination.vue'
// import CreateExamination from './CreateExamination.vue'

export default {
data:()=>({
    CreateExaminationDialog:false,
    EditExaminationDialog:false,
    examDuration:'',
    examNumberOfQuestions:'',
    examNumberOfSections:'',
    examNumberOfOptionPerQuestion:'',
    examExaminationFlow:'',
    examsettingName:'',
    examsettingUniqueId:'',
    examinationItems:null,
    search: '',
    headers: [
        {
        text: 'Examination Category Title',
        value: 'title',
        },
        { text: 'Examination Setting', value: 'examinationSetting' },
        { text: 'edit', value: 'unique_id' },


    ],
    examinations: [] 
}),
components:{
    // CreateExamination,
    EditExamination,
},
mounted(){
  this.$store.dispatch('LoadExaminations')
    // this.$apollo.query({
    //     query:allExaminations,
    // }).then((response)=>{
    //     console.log("Examination Settings")
    //     console.log(response.data.allExaminations.edges)
    //     response.data.allExaminations.edges.forEach(element=>{
    //         this.examinations.push({'title':element.node.examinationTitle, 'unique_id':element.node.examinationUniqueId})
    //     })
    // })
},methods:{
    openDialog:function(value){
        console.log(value)
        this.examinationItems=value
        this.EditExaminationDialog=true
    }
}
}
</script>

<style>

</style>