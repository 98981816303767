import { apolloClient } from "@/vue-apollo";
import staffProfile from "../../graphql/StaffProfile/staffProfile.graphql";

export default {
  state: {
    staffVenues: null,
    staffTimetables: [],
  },
  mutations: {
    LoadStaffVenues(state, data) {
      state.staffVenues = data.venues;
      state.staffTimetables.push(data.timetables);
      // console.log(state.staffVenues,
      // state.staffTimetables)
    },
    clearStaffVenues(state) {
      state.staffVenues = null;
    },
  },
  actions: {
    async LoadStaffVenues(context, data) {
      context.commit("clearStaffVenues");
      apolloClient
        .query({
          query: staffProfile,
          variables: {
            staffUser: data,
          },
        })
        .then((response) => {
          response.data.staffProfile.results.forEach((element) => {
            console.log(element.node.staffVenue.timetableVenue.edges);
            let staffTimetables;
            element.staffVenue.timetableVenue.edges.forEach((timetable) => {
              staffTimetables = {
                key: timetable.primaryKey,
                name: timetable.timetableName,
                date: timetable.timetableDate,
                time: timetable.timetableTime,
              };
            });
            let data = {
              venues: element.staffVenue.venuesUniqueId,
              timetables: staffTimetables,
            };
            context.commit("LoadStaffVenues", data);
          });
        });
    },
  },
};
