<template>
  <v-row justify="center">
    <v-dialog
      v-model="CreateBookingSettingDialog"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Create Examination</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                   <v-text-field
                        v-model="settingsMaximumBookingTime"
                        label="Maximum Booking Time *"
                        required
                        prepend-icon="mdi-book-clock"
                        type="number"
                    >
                     </v-text-field>
                     <v-text-field
                        v-model="settingsMaximumCancelationTime"
                        label="Maximum Cancelation Time *"
                        required
                        prepend-icon="mdi-cancel"
                        type="number"
                    >
                    </v-text-field>
                    </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            class="white--text rounded-lg"
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            color="#EB8822"
            class="white--text rounded-lg"
           @click="createBookingSettings"
          >
            <v-icon>mdi-check-all</v-icon> Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

  export default {
    components:{
        
    },
    data: () => ({
        checkbox:false,
        settingsMaximumBookingTime:null,
        settingsMaximumCancelationTime:null,
    }),
    props: ["CreateBookingSettingDialog"],
    mounted(){
      // this.$apollo.query({
      //   // query:
      // })
    },
    methods:{
      createBookingSettings: function(){
        let data={
          settingsMaximumBookingTime:this.settingsMaximumBookingTime,
          settingsMaximumCancelationTime:this.settingsMaximumCancelationTime,
        }
        this.$store.dispatch('addBookingSetting',data).finally(()=>{
          this.settingsMaximumBookingTime=null
          this.settingsMaximumCancelationTime=null
          this.$emit('close')
        })
      }
      
    }
  }
</script>