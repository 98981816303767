<template>
  <v-row justify="center">
    <v-dialog
      v-model="createDialog"
      max-width="60%"
    >
    <v-card>
    <v-card-title>
    <span class="text-h5"> <v-btn
      class="ma-2"
      outlined
      small
      fab
      color="indigo"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>{{ questions }}</span>
    </v-card-title>

    <v-card-text>
    <v-container>
    <v-row>
    <v-col
        cols="12"
        sm="12"
        md="12"
    >
        <v-text-field
        v-model="QuestionDescription"
        label="Question Descriptions"
        ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="6"
        md="4"
    >
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="12"
    >
    <v-select
        v-model="DifficultLevel"
        :items="difficultLevel"
        label="Difficult Level"
        item-text="description"
        item-value="code"
        dense
        chips
    ></v-select>
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="12"
    >
    <v-checkbox
    color="#EB8822"
        v-model="hasAttachment"
        :label="`Question has Image Attachement`"
    ></v-checkbox>
      <div v-if="hasAttachment" style="width:100%; padding:20px">
        <vue-dropzone id="dropzone" ref="myVueDropzone" v-model="attachmentFile" :options="dropzoneOptions" @vdropzone-file-added="uploadAttachment"></vue-dropzone>
      </div>



    </v-col>

    </v-row>
    <v-row>

    <v-col
        cols="12"
        sm="12"
        md="12"
    >
        <v-text-field
                v-model="optionData"
                flat
                label="Add Answer Options..."
                outlined
                dense

                @keydown.enter="addAnswerOptions"
                >
        <template v-slot:append>
    <v-btn
        absolute
        bottom
        color="indigo"
        right
        fab
        @click="addAnswerOptions"
        small
    >
        <v-icon class="white--text">mdi-plus-circle-outline</v-icon>
    </v-btn>
        </template>
        </v-text-field>
        <v-radio-group v-model="answerIsCorrect" v-if="answerOptions.length >0">
        <template v-slot:label>
            <div>Select the <strong style="color:#EB8822">correct</strong> answer option
            </div>
        </template>
        <v-radio
            color="#EB8822"
            v-for="(n,index) in answerOptions"
            :key="index"
            :label="`${n}`"
            :value="n"
        >
            <template v-slot:label>
            <div>{{n}}</div>
            </template>
        </v-radio>
        </v-radio-group>

    </v-col>
    </v-row>
    </v-container>
    </v-card-text>

    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
    color="pink"
    dark
    fab
    small
    @click="$emit('close')"
    >
    <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-btn  color="#EB8822" dark fab small @click="createNewQuestion">
    <v-icon>mdi-content-save</v-icon>
    </v-btn>
    </v-card-actions>
    </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import uploadMutation from '../../../graphql/dts_questions_graphql/uploadMutation.graphql'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    components: {
    vueDropzone: vue2Dropzone
  },
    props:['createDialog','questions','categoryId'],
    data(){
        return{
          editedItem:'',
          optionData:'',
          answerOptions:[],
          hasAttachment:false,
          attachmentFile:null,
          fullFileName:"",
          fileName:"",
          previewUrl:null,
          answerIsCorrect:null,
          fileUpload:null,
          QuestionDescription:null,
          DifficultLevel:null,
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          difficultLevel:[
            {"code":"es","description":"Easy"},
            {"code":"md","description":"Medium"},
            {"code":"hd","description":"Hard"},
            {"code":"xd","description":"Extra Hard"},
          ],
          CategoryId:null,
          dropzoneOptions: {
            url: 'https://httpbin.org/post',
            autoProcessQueue:false,
            thumbnailWidth: 150,
            maxFilesize: 60,
            headers: { "My-Awesome-Header": "header value" }
          },
        }
    },



    methods: {
      previewImage:function (){
        this.previewUrl=URL.createObjectURL(this.attachment)
      },
      addAnswerOptions(){
        if(this.optionData!==null){
          this.answerOptions.push(this.optionData)
          this.optionData=null
        }
      },
      createNewQuestion(){
        let data ={
            questionDescriptions:this.QuestionDescription,
            questionCategory:this.CategoryId,
            questionDifficulties:this.DifficultLevel,
            answerOptions:this.answerOptions,
            answerIsCorrect:this.answerIsCorrect,
            hasAttachment:this.hasAttachment,
            attachmentFileName:this.fileName,
            attachmentPathName:this.fullFileName
          }


        this.$store.dispatch('createQuestions',data).finally(
          this.$emit('close')
        )
        this.$swal({
          toast:true,
          position:'top-right',
          title:'Question created Succesfully!',
          timerProgressBar:true,
          timer:10000,
          icon:'success',
          showConfirmButton:false,
          animation:false,
        });
      },
      uploadAttachment:function (file){
        this.$apollo.mutate({
          mutation:uploadMutation,
          variables:{
            file:file
          }
        }).then((response)=>{
          console.log(response.data.uploadMutation)
          if(response.data.uploadMutation.success){
            this.$swal({
              toast:true,
              position:'top-right',
              title:'Attachment Added Succesfully!',
              timerProgressBar:true,
              timer:10000,
              icon:'success',
              showConfirmButton:false,
              animation:false,
            })
          }else{
            this.$swal({
              toast:true,
              position:'top-right',
              title:'Oops! Something went wrong',
              text:'Please try again, If the problem persists contact your Admin',
              timerProgressBar:true,
              timer:10000,
              icon:'error',
              showConfirmButton:false,
              animation:false,
            })
          }
          this.hasAttachment=response.data.uploadMutation.success
          this.fullFileName=response.data.uploadMutation.fullFileName
          this.fileName=response.data.uploadMutation.fileName
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.questions[this.editedIndex], this.editedItem)
        } else {
          this.questions.push(this.editedItem)
        }
        this.close()
      },
    },
    watch: {
      createDialog:function(){
        this.CategoryId=this.categoryId
      }
    },
}
</script>