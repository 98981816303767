import '@babel/polyfill';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import "./assets/css/main.css";
import store from "./store";
import VueCryptojs from "vue-cryptojs";
import ECharts from "vue-echarts";
import { use } from "echarts/core";
import VueMoment from "vue-moment";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment-timezone";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import "core-js/stable";
import "regenerator-runtime/runtime";
// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
//apex-charts
import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

// register globally (or you can do it locally)
Vue.component("v-chart", ECharts);
Vue.use(VueMoment, {
  moment,
});
Vue.use(VueSweetalert2);
Vue.use(VueCryptojs);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
