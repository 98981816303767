<template>
<v-container fluid>
        <CreateExaminationSetting :CreateExaminationSettingDialog="CreateExaminationSettingDialog" @close="CreateExaminationSettingDialog=false"/>
        <EditExaminationSetting :EditExaminationSettingDialog="EditExaminationSettingDialog" @close="EditExaminationSettingDialog=false" :ExaminationSettingItems="examinationSettingsItems"/>
    <v-row>
        <v-tooltip bottom color="#EB8822">
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="CreateExaminationSettingDialog =true"  v-bind="attrs" v-on="on"  right dark color="#EB8822 " class="rounded-lg ml-5 text-capitalize" style="margin:5px">
                    <v-icon class="white--text" small>mdi-plus-circle</v-icon>  Create Examination Setting
                    </v-btn>
            </template>
            <span>Create Examination Settings</span>
        </v-tooltip>
    </v-row>
    <v-row>
        <v-col sm="12"> 
            <div style="padding:10px">
                <template>
                    <v-card>
                        <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :expanded.sync="expanded"
                        :single-expand="true"
                        item-key="name"
                        :items="$store.state.ExaminationSettingsStore.allExaminationSettings"
                        :search="search"
                        show-expand
                        >
                          <template v-slot:item.edit="{ item }">
                              <v-btn fab small text @click="openDialog(item)">
                                  <v-icon color="orange" small>mdi-pen</v-icon>
                              </v-btn>
                          </template>
<!--                          <template v-slot:item.sections="{ item }">-->
<!--                            <v-tooltip color="primary" top>-->
<!--                              <template v-slot:activator="{ on, attrs }">-->
<!--                                <v-btn class="rounded-xl" color="primary" dense elevation="0" v-bind="attrs"-->
<!--                                       v-on="on">-->
<!--                              <span  v-for="section in item.sections" :key="section.primaryKey">-->
<!--                               {{section.sectionName}},&nbsp;-->

<!--                             </span>-->
<!--                                </v-btn>-->
<!--                              </template>-->
<!--                              <span>-->
<!--                                <span  v-for="section in item.sections" :key="section.primaryKey">-->
<!--                                   {{section.sectionName}}:&nbsp;-->
<!--                                    <span v-for="category in section.categories" :key="'section'+category">{{category.name}}({{category.noq}})</span>-->
<!--                                 </span>-->
<!--                              </span>-->
<!--                            </v-tooltip>-->
<!--                          </template>-->
                          <template v-slot:expanded-item="{ headers, item }" >
                            <td :colspan="headers.length">
                              <span class="rounded-xl" color="primary" dense elevation="0">
                                <span  v-for="section in item.sections" :key="section.primaryKey">
                                   {{section.sectionName}}:&nbsp;
                                    <span v-for="category in section.categories" :key="'section'+category">{{category.name}}({{category.noq}}),</span>
                                 </span>
                                </span>
                            </td>
                          </template>
                        </v-data-table>
                    </v-card>
                </template>
            </div>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import EditExaminationSetting from './EditExaminationSetting.vue'
import CreateExaminationSetting from './CreateExaminationSetting.vue'
// import allExaminationSettings from '../../../../graphql/Examinations/allExaminationSettings.gql'
export default {
data:()=>({
    CreateExaminationSettingDialog:false,
    EditExaminationSettingDialog:false,
    examDuration:'',
    examNumberOfQuestions:'',
    examNumberOfSections:'',
    examNumberOfOptionPerQuestion:'',
    examExaminationFlow:'',
    examsettingName:'',
    examsettingUniqueId:'',
    examinationSettingsItems:null,
    search: '',
    expanded: [],
    headers: [
        {
        text: 'Setting Name',
        value: 'name',
        },
        { text: 'Duration(Hrs)', value: 'duration' },
        { text: 'No. of Questions', value: 'questions' },
        // { text: 'Examination Sections', value: 'sections' },
        { text: 'Options per Question', value: 'opq' },
        { text: 'Flow', value: 'flow' },
        { text: 'Edit', value: 'edit'},
        { text: '', value: 'data-table-expand' },

    ],
}),
components:{
    CreateExaminationSetting,
    EditExaminationSetting,
},
mounted(){

  this.$store.dispatch('LoadExaminationSettings')
  this.$store.dispatch('createExaminationSection')
},

methods:{
    openDialog:function(value){
        this.examinationSettingsItems=value
        this.EditExaminationSettingDialog=true
    }
}

}
</script>

<style>

</style>