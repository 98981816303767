<template>
  <v-row justify="center">
    <v-dialog
      v-model="CreateBookingCancelationLimitDialog"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Create Cancelation Limit</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                   <v-text-field
                        v-model="limitNumber"
                        label="Cancelation Limit *"
                        required
                        type="number"
                        prepend-icon="mdi-cancel"
                    >
                    </v-text-field>
                    </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="#EB8822"
            fab
            small
            class="white--text"
           @click="UpdateCancelationLimit"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>
// import updateCancelationLimit from '../../../../graphql/updateCancelationLimit.gql'

  export default {
    components:{
        
    },
    data: () => ({
        checkbox:false,
        limitNumber:null,
    }),
    props: ["CreateBookingCancelationLimitDialog"],
    mounted(){
      
    },
    methods:{
      UpdateCancelationLimit: function(){
        let data={
          limitNumber:this.limitNumber,
        }
        this.$store.dispatch('addCancellationLimit',data).finally(()=>{
          this.limitNumber=null
          this.$emit('close')
        })
      }
      
    }
  }
</script>