<template>
  <v-container fluid>
    <v-row>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="rounded-lg px-4 text-capitalize"
            color="success white--text"
            right
            style="margin: 5px"
            v-bind="attrs"
            @click="OpenNewAccessForm = !OpenNewAccessForm"
            v-on="on"
            ><v-icon small>mdi-lock-plus</v-icon>&nbsp; Create User Role</v-btn
          >
        </template>
        <span>Add New Access Level</span>
      </v-tooltip>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="rounded-lg px-4 text-capitalize"
            color="primary white--text px-4"
            right
            style="margin: 5px"
            v-bind="attrs"
            @click="memberDialog = !memberDialog"
            v-on="on"
            ><v-icon small>mdi-account-plus</v-icon>&nbsp; Create User</v-btn
          >
        </template>
        <span>Create New User</span>
      </v-tooltip>
    </v-row>

    <v-row>
      <v-col sm="12">
        <v-card elevation="6">
          <!-- tabs -->
          <v-tabs v-model="tab" show-arrows>
            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
              @click="$store.dispatch(tab.action, {isStaff:tab.isStaff});"
            >
              <v-icon class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <staffs :boardMembers="items"></staffs>
            </v-tab-item>

            <v-tab-item>
              <candidates />
            </v-tab-item>

            <v-tab-item>
              <roles />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <AddNewUser
      :OpenNewUserForm="OpenNewUserForm"
      @close="OpenNewUserForm = !OpenNewUserForm"
    />
    <AddNewRole
      :OpenNewAccessForm="OpenNewAccessForm"
      @close="OpenNewAccessForm = !OpenNewAccessForm"
    />

    <v-dialog v-model="memberDialog" max-width="50%" persistent>
      <v-card>
        <v-card-title>
          <v-icon>mdi-account-plus-outline</v-icon>
          <span> Add System Users</span>
        </v-card-title>
        <v-card-text>
          <span>You can create new users or load the users from RRIMS </span>
          <v-radio-group row>
            <v-col cols="6" md="6" sm="6">
              <v-card class="pa-5 rounded-lg" outlined>
                <v-radio
                  label="Loading from RRIMS"
                  value="radio-1"
                  @change="
                    inviteMember = !inviteMember;
                    disabled = true;
                  "
                ></v-radio>
              </v-card>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-card class="pa-5 rounded-lg" outlined>
                <v-radio
                  label="Create New User"
                  value="radio-2"
                  @change="
                    inviteMember = false;
                    disabled = false;
                  "
                ></v-radio>
              </v-card>
            </v-col>
          </v-radio-group>

          <v-text-field
            v-if="inviteMember == true"
            v-model="message"
            :hint="searchResults"
            clearable
            label="Write First & Last or Email name to Load user"
            type="text"
          >
            <template v-slot:prepend>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                Type Member Email, Click search button to find if user exist in
                RRIMS database
              </v-tooltip>
            </template>
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                  v-if="loading"
                  color="info"
                  indeterminate
                  size="24"
                ></v-progress-circular>
                <v-icon v-else>mdi-email-sync</v-icon>
              </v-fade-transition>
            </template>
            <template v-slot:append-outer>
              <v-btn
                color="primary"
                flat
                icon
                rounded
                v-bind="attrs"
                @click="clickMe"
                v-on="on"
              >
                <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div v-if="inviteMember == true">
            <v-checkbox
              v-for="member in inviteMemberList"
              :key="member.name"
              v-model="member.checked"
              :label="`${member.name}`"
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="rounded-lg pa-5"
            color="error"
            outlined
            right
            @click="memberDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="inviteMember"
            :disabled="invitationBtn"
            class="rounded-lg pa-5"
            color="success"
            right
            @click="progressShown = true"
          >
            Store Records <v-icon>mdi-email-fast-outline</v-icon>
          </v-btn>
          <v-btn
            v-else
            :disabled="disabled"
            class="rounded-lg pa-5"
            color="primary"
            right
            @click="OpenNewUserFormWindow()"
          >
            <v-icon>mdi-plus</v-icon> Add User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AddNewUser from "./AddNewUser.vue";
import Staffs from "./Staffs.vue";
import Candidates from "./Candidates.vue";
import AddNewRole from "./AddNewRole.vue";
import Roles from "./Roles.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AddNewUser,
    Staffs,
    Candidates,
    AddNewRole,
    Roles,
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },

  data: () => ({
    message: "",
    disabled: true,
    progressShown: false,
    invitationBtn: true,
    inviteMemberList: [],
    searchResults: "Eg. firstname.lastname@doamin.xx",
    loading: false,
    tab: "",
    inviteMember: false,
    memberDialog: false,
    OpenNewUserForm: false,
    OpenNewAccessForm: false,
    allUsersFromDB: {},
    items: [
      { header: "Staff Users" },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "John Smith",
        subtitle: `john.smith@email.com`,
        active: true,
        activeMessage: "Member is Active",
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Rachel Daniel",
        subtitle: `rachel.daniel@email.com`,
        active: true,
        activeMessage: "Member is Active",
      },
    ],
    nonitems: [
      { header: "Candidates" },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "John Smith",
        subtitle: `john.smith@email.com`,
        active: true,
        activeMessage: "Member is Active",
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Rachel Daniel",
        subtitle: `rachel.daniel@email.com`,
        active: true,
        activeMessage: "Member is Active",
      },
    ],
    tabs: [
      {
        title: "Staff Users",
        icon: "mdi-account-star-outline",
        action: "LoadStaff",
        isStaff: true,
      },
      {
        title: "Candidates",
        icon: "mdi-account-outline",
        action: "LoadCandidate",
        isStaff: false,
      },
      { title: "Access Levels", icon: "mdi-email-fast-outline" },
    ],
  }),
  methods: {
    clickMe() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.invitationBtn = false;
        this.inviteMemberList.push({
          name: "rachel.daniel@email.com",
          checked: true,
        });
        this.inviteMemberList.push({
          name: "john.smith@email.com",
          checked: false,
        });
        this.searchResults = `Your search results`;
      }, 2000);
    },
    OpenNewUserFormWindow() {
      this.OpenNewUserForm = true;
      this.memberDialog = false;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.$store.dispatch("LoadStaff", true);
  },
};
</script>

<style></style>
