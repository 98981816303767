import {apolloClient, onLogout} from '@/vue-apollo'
import {LOGGED_IN_USER} from '@/graphql/queries'
import {LOGIN_USER, REGISTER_USER} from '@/graphql/mutations'
import router from '../router/index.js'
import Vue from "vue";

export default {
  state: {
    token: localStorage.getItem('latra-dts-token')|| null,
    user: {},
    role:'',
    roleIsActive:false,
    authStatus: false,
    permissionMap:[],
    // permissionMap: {
    //   can_invigilate:false,
    //   can_take_exam:false,
    //   can_add_question_bank:false,
    //   can_add_question:false,
    //   can_edit_question:false,
    //   can_upload_question:false,
    //   can_setup_examination:false,
    //   can_manage_system_settings:false,
    //   can_add_venues:false,
    //   can_edit_venues:false,
    //   can_deactivate_venues:false,
    //   can_create_timetable_session:false,
    //   can_publish_timetable_session:false,
    //   can_cancel_session:false,
    //   can_edit_timetable_session:false,
    // }

  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.authStatus,
    user: state => state.user,
    permissionMap: state => state.permissionMap,
    roleIsActive: state => state.roleIsActive,
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    LOGIN_USER (state, user) {
      state.authStatus = true
      state.user = { ...user }
    },
    LOGOUT_USER (state) {
      state.authStatus = ''
      state.token = '' && localStorage.removeItem('latra-dts-token')
      state=null
    },
    SET_ROLE_AND_PERMISSION(state,role_and_permission){
      state.permissionMap=role_and_permission.permissionList
      state.role=role_and_permission.role
      state.roleIsActive=role_and_permission.roleIsActive
      console.log(state.roleIsActive)
    }
  },
  actions: {
    async register ({ commit }, authDetails) {
      try {
        const { data } = await apolloClient.mutate({ mutation: REGISTER_USER, variables: { ...authDetails } })
        const token = JSON.stringify(data.createUser.token)
        commit('SET_TOKEN', token)
        // localStorage.setItem('latra-dts-token', token)
        // dispatch('setUser')
      } catch (e) {
        console.log(e)
      }
    },
    async login ({ commit }, authDetails) {
      try {
        const { data,error } = await apolloClient.mutate({ mutation: LOGIN_USER, variables: { ...authDetails } })
        if (data.tokenAuth){


        const token = JSON.stringify(data.tokenAuth.token)
        commit('SET_TOKEN', token)
          let allPermissions=[]
          data.tokenAuth.user.userroleUser.edges[0].node.userroleRole.templateRole.edges.forEach(permissions=>{
            if(permissions.node.templatePermission.permissionIsActive)
              allPermissions.push(permissions.node.templatePermission)
          })
        let role_and_permission={
          role:data.tokenAuth.user.userroleUser.edges[0].node.userroleRole.roleDescriptions,
          roleIsActive:data.tokenAuth.user.userroleUser.edges[0].node.userroleRole.roleIsActive,
          permissionList: allPermissions
        }
        commit('LOGIN_USER', data.tokenAuth.user)
          commit('SET_ROLE_AND_PERMISSION',role_and_permission)

        localStorage.setItem('latra-dts-token', token)
        router.push('/')
        }else{
          console.log(error)
        }
      } catch (e) {
        console.log(e)
        Vue.swal({
          toast:true,
          position:'top',
          title:'Wrong Credentials',
          text:'It looks like either the e-mail or password you entered is wrong. Please try again.',
          timerProgressBar:true,
          timer:10000,
          icon:'error',
          showConfirmButton:false,
          animation:false,
        });
      }
    },
    async setUser ({ commit }) {
      const { data } = await apolloClient.query({ query: LOGGED_IN_USER})
      commit('LOGIN_USER', data.me)
      // console.log(data.allUsers.edges[0].node);
    },
    async logOut ({ commit }) {
      // console.log("Wee have been logged out")
      commit('LOGOUT_USER')
      onLogout(apolloClient)

    }
  },

}