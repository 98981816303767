<template>
  <v-row justify="center">
    <v-dialog
        v-model="EditQuestionDialog"
        max-width="60%"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Question</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-text-field
                    v-model="QuestionDescription"
                    label="Question Descriptions"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
                  sm="6"
              >
              </v-col>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-select
                    v-model="DifficultLevel"
                    :items="difficultLevel"
                    chips
                    dense
                    item-text="description"
                    item-value="code"
                    label="Difficult Level"
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-checkbox
                    v-model="hasAttachment"
                    :label="`Question has Image Attachement`"
                    color="#EB8822"
                ></v-checkbox>
                <div v-if="hasAttachment" style="width:100%; padding:20px">
                  <vue-dropzone id="dropzone" ref="myVueDropzone" v-model="attachmentFile" :options="dropzoneOptions" @vdropzone-file-added="uploadAttachment"></vue-dropzone>
                  <v-img
                      :src="attachmentPathName"
                      contain
                      lazy-src="https://picsum.photos/id/11/10/6"
                      max-height="150"
                      max-width="250"
                  ></v-img>
                </div>



              </v-col>

            </v-row>
            <v-row>

              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-text-field
                    v-model="optionData"
                    dense
                    flat
                    label="Add Answer Options..."
                    outlined
                    @keydown.enter="addAnswerOptions"
                >
                  <template v-slot:append>
                    <v-btn
                        absolute
                        bottom
                        color="primary"
                        fab
                        right
                        small
                        @click="addAnswerOptions"
                    >
                      <v-icon class="white--text">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-radio-group v-if="answerOptions.length >0" v-model="answerIsCorrect">
                  <template v-slot:label>
                    <div>Select the <strong style="color:#EB8822">correct</strong> answer option
                    </div>
                  </template>
                  <v-radio
                      v-for="(n,index) in answerOptions"
                      :key="index"
                      :label="`${n}`"
                      :value="`${n}`"
                      color="#EB8822"
                  >
                    <template v-slot:label>
                      <div>{{n}}</div>
                    </template>
                  </v-radio>
                </v-radio-group>

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="rounded-lg"
              dark
              @click="$emit('close')"
              color="error"
          >
            <v-icon small>mdi-close</v-icon> Cancel
          </v-btn>
          <v-btn  class="rounded-lg" color="primary" dark @click="editQuestion">
            <v-icon small>mdi-content-save</v-icon> Save Updates
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import uploadMutation from "../../../graphql/dts_questions_graphql/uploadMutation.graphql";
// import createQuestion from '../../../graphql/dts_questions_graphql/createQuestion.gql'

export default {
  components: {vueDropzone: vue2Dropzone},
  props:['EditQuestionDialog','questionDetails'],
  data(){
    return{
      editedItem:'',
      optionData:'',
      answerOptions:[],
      hasAttachment:false,
      attachmentFile:null,
      attachmentFileName:"",
      attachmentPathName:"",
      answerIsCorrect:null,
      correctAnswer:null,
      QuestionDescription:null,
      DifficultLevel:null,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      difficultLevel:[
        {"code":"es","description":"Easy"},
        {"code":"md","description":"Medium"},
        {"code":"hd","description":"Hard"},
        {"code":"xd","description":"Extra Hard"},
      ],
      CategoryId:null,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        autoProcessQueue:false,
        thumbnailWidth: 150,
        maxFilesize: 60,
        headers: { "My-Awesome-Header": "header value" }
      },
    }
  },



  methods: {
    addAnswerOptions(){
      if(this.optionData!==null){
        this.answerOptions.push(this.optionData)
        this.optionData=null
      }
    },
    editQuestion(){

      let data ={
        questionUniqueId:this.questionUniqueId,
        questionDescriptions:this.QuestionDescription,
        questionCategory:this.CategoryId,
        questionDifficulties:this.DifficultLevel,
        answerOptions:this.answerOptions,
        answerIsCorrect:this.answerIsCorrect,
        hasAttachment:this.hasAttachment,
        attachmentFileName:this.attachmentFileName,
        attachmentPathName:this.attachmentPathName.replace(process.env.VUE_APP_URL,'')
      }
      this.$store.dispatch('editQuestions',data).finally(
          this.$emit('close')
      )
      this.$swal({
        toast:true,
        position:'top-right',
        title:'Question Update Succesfully!',
        timerProgressBar:true,
        timer:10000,
        icon:'success',
        showConfirmButton:false,
        animation:false,
      });
    },
    uploadAttachment:function (file){
      this.$apollo.mutate({
        mutation:uploadMutation,
        variables:{
          file:file
        }
      }).then((response)=>{
        console.log(response.data.uploadMutation)
        if(response.data.uploadMutation.success){
          this.$swal({
            toast:true,
            position:'top-right',
            title:'Attachment Added Succesfully!',
            timerProgressBar:true,
            timer:10000,
            icon:'success',
            showConfirmButton:false,
            animation:false,
          })
        }else{
          this.$swal({
            toast:true,
            position:'top-right',
            title:'Oops! Something went wrong',
            text:'Please try again, If the problem persists contact your Admin',
            timerProgressBar:true,
            timer:10000,
            icon:'error',
            showConfirmButton:false,
            animation:false,
          })
        }
        this.hasAttachment=response.data.uploadMutation.success
        this.attachmentPathName=response.data.uploadMutation.fullFileName
        this.attachmentFileName=response.data.uploadMutation.fileName
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.questions[this.editedIndex], this.editedItem)
      } else {
        this.questions.push(this.editedItem)
      }
      this.close()
    },
  },
  watch: {
    EditQuestionDialog:function(){
      // this.CategoryId=this.categoryId
      console.log(this.questionDetails)
      this.QuestionDescription=this.questionDetails.description
      this.questionUniqueId=this.questionDetails.questionUniqueId
      this.hasAttachment=this.questionDetails.hasAttachment
      this.optionData=this.questionDetails.optionData
      this.CategoryId=this.questionDetails.categoryId
      this.DifficultLevel=this.questionDetails.code.toLowerCase()
      this.answerOptions=[]
      this.attachmentFileName=this.questionDetails.fileName
      if(this.hasAttachment)
        this.attachmentPathName=process.env.VUE_APP_URL+this.questionDetails.filePath
        // this.attachmentPathName="https://latradts.mikutano.co.tz:8090"+this.questionDetails.filePath
      // this.attachmentPathName="http://172.17.17.96:8000"+this.questionDetails.filePath
      this.questionDetails.answerOptions.forEach(answerOption=>{
        this.answerOptions.push(answerOption.answerDescriptions)
        if(answerOption.answerIsCorrect){
          this.answerIsCorrect=answerOption.answerDescriptions
        }
      })

    }
  },
}
</script>