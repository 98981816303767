var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#eee"}},[_c('v-tabs',{attrs:{"centered":"","color":"#ef8934"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',[_c('v-icon',[_vm._v("mdi-cogs")]),_vm._v(" Booking Management")],1),_c('v-tab',[_c('v-icon',[_vm._v("mdi-cancel")]),_vm._v(" Cancelation Limit")],1)],1)],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('EditBookingSetting',{attrs:{"EditBookingSettingDialog":_vm.EditBookingSettingDialog,"BookingSettingsItems":_vm.bookingSettingsItems},on:{"close":function($event){_vm.EditBookingSettingDialog=false}}}),_c('CreateBookingSetting',{attrs:{"CreateBookingSettingDialog":_vm.CreateBookingSettingDialog},on:{"close":function($event){_vm.CreateBookingSettingDialog=false}}}),_c('CreateBookingCancelationLimit',{attrs:{"CreateBookingCancelationLimitDialog":_vm.CreateBookingCancelationLimitDialog},on:{"close":function($event){_vm.CreateBookingCancelationLimitDialog=false}}}),_c('EditBookingCancelationLimit',{attrs:{"EditBookingCancelationLimitDialog":_vm.EditBookingCancelationLimitDialog,"CancelationLimitItems":_vm.cancelationLimitItems},on:{"close":function($event){_vm.EditBookingCancelationLimitDialog=false}}}),_c('v-row',[_c('v-tooltip',{attrs:{"bottom":"","color":"#EB8822"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg ml-5",staticStyle:{"margin":"5px"},attrs:{"right":"","dark":"","color":"#EB8822 "},on:{"click":function($event){_vm.CreateBookingSettingDialog =true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Booking Setting ")],1)]}}])},[_c('span',[_vm._v("Create Booking Setting")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticStyle:{"padding":"10px"}},[[_c('v-card',[_c('v-card-title',[_vm._v(" Booking Settings "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.BookingSettingsStore.BookingSettings,"search":_vm.search},scopedSlots:_vm._u([{key:"item.unique_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v("mdi-pen")])],1)]}}])})],1)]],2)])],1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":"","color":"#EB8822"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg ml-5",staticStyle:{"margin":"5px"},attrs:{"right":"","dark":"","color":"#EB8822 "},on:{"click":function($event){_vm.CreateBookingCancelationLimitDialog =true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Cancelation Limit ")],1)]}}])},[_c('span',[_vm._v("Create Cancelation Limit")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticStyle:{"padding":"10px"}},[[_c('v-card',[_c('v-card-title',[_vm._v(" Cancelation Limit "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersCancel,"items":_vm.$store.state.CancellationLimitStore.cancellationLimits,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v("mdi-pen")])],1)]}}])})],1)]],2)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }