<template>
  <v-row
    class="d-flex justify-content-center"
    justify="center"
    style="width: 100%"
  >
    <v-col cols="12" md="12">
      <template>
        <v-card
          v-if="!verificationAllowedStatus"
          class="mx-auto pa-5 rounded-lg"
          max-width="600"
        >
          <v-card-text>
            <div>Maelekezo kwa Msimamizi wa Mtihani</div>
            <p class="text-h4 text--primary">Muhimu</p>
            <div class="text--primary">
              <ol>
                <li>
                  Hakikisha watahiniwa wote wana vifaa visivyo na
                  <strong>hitilafu</strong> kabla ya kuendelea
                </li>
                <li>
                  Hakikisha umetoa <strong>Maelekezo</strong> kabla haujabonyeza
                  kitufe cha kuruhusu uthibitishaji
                </li>
              </ol>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="!verificationAllowedStatus"
              class="mx-2 px-5"
              color="primary"
              dark
              @click="allowVerification"
            >
              Ruhusu Uthibitishaji
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-expand-transition>
          <v-card
            v-if="!examHasStarted && verificationAllowedStatus"
            class="mx-auto pa-5 rounded-lg"
            max-width="600"
          >
            <v-card-text>
              <p class="text-h4 text--primary mb-0">Muhimu</p>
              <div>Maelekezo kwa Msimamizi wa Mtihani</div>
              <div class="text--primary">
                <ol>
                  <li>
                    Hakikisha watahiniwa wote waliokuwepo kipindi cha
                    uthibitishaji <strong>wamemaliza</strong> hatua ya
                    uthibitishaji kabla ya kuendelea
                  </li>
                  <li>
                    Hakikisha umetoa <strong>Maelekezo</strong> kabla
                    haujabonyeza kitufe cha kuruhusu kuanza mtihani
                  </li>
                </ol>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="mx-2 rounded-lg px-5"
                color="success"
                dark
                @click="initiateStartExam"
              >
                Ruhusu Kuanza Mtihani
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
        <v-expand-transition>
          <v-row>
            <v-col cols="12" md="8" sm="12">
              <v-card
                v-if="examHasStarted && verificationAllowedStatus"
                class="pa-5 rounded-lg"
              >
                <v-card-text>
                  <p>Session has started</p>
                  <p><b>Below are the times for each exams</b></p>
                  <span
                    v-for="(examinationsDetail, index) in examinationsDetails"
                    :key="examinationsDetail.examination + index"
                  >
                    <v-chip
                      v-if="!examinationsDetail.examination_is_finished"
                      class="rounded-xl mr-2 mt-1"
                      color="warning"
                      >{{ examinationsDetail.examination }}:&nbsp;<b>{{
                        examinationsDetail.remaining_time
                      }}</b></v-chip
                    >
                  </span>
                </v-card-text>
                <v-card-actions>
                  <!--              <v-btn-->
                  <!--                  class="mx-2 rounded-lg px-5"-->
                  <!--                  color="error"-->
                  <!--                  dark-->
                  <!--                  @click="endExam"-->
                  <!--              >-->
                  <!--                Funga Mtihani-->
                  <!--              </v-btn>-->
                </v-card-actions>
              </v-card>
              <v-expand-transition>
                <v-card
                  v-if="
                    !examEnded && examHasStarted && verificationAllowedStatus
                  "
                  class="mt-5"
                >
                  <v-card-title>Candidate Screens</v-card-title>
                  <!-- <v-btn color="primary" @click="startCameraInvigilation">Start Camera Invigilation </v-btn> -->
                  <v-card-text>
                    <v-row hidden>
                      <div class="col-md-3" hidden>
                        <video
                          id="local-video"
                          autoplay="autoplay"
                          muted="muted"
                        ></video>
                      </div>
                    </v-row>
<!--                    <v-row id="video-chat-container">-->
<!--                      &lt;!&ndash; <template v-for="remoteVideoComponentSingle in remoteVideoComponents" cols="12" md="4">-->
<!--                        {{remoteVideoComponentSingle}}-->
<!--                      </template> &ndash;&gt;-->
<!--                    </v-row>-->
                  </v-card-text>
                </v-card>
              </v-expand-transition>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-card
                v-if="examHasStarted && verificationAllowedStatus"
                class="pa-5 rounded-lg"
                max-width="600"
              >
                <v-card-text>
                  <p><b>Students permission Requests</b></p>
                  <v-data-table
                    :headers="headers"
                    :items="$store.state.CandidatePermission.permissionRequests"
                    class="elevation-0"
                  >
                    <template v-slot:item.bookingNumber="{ item }">
                      <v-btn
                        class="mx-1"
                        color="success"
                        elevation="0"
                        rounded
                        small
                        @click="grantDenyPermission(item, true)"
                        ><v-icon>mdi-check</v-icon></v-btn
                      >
                      <v-btn
                        class="mx-1"
                        color="error"
                        elevation="0"
                        rounded
                        small
                        @click="grantDenyPermission(item, false)"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <!--              <v-btn-->
                  <!--                  class="mx-2 rounded-lg px-5"-->
                  <!--                  color="error"-->
                  <!--                  dark-->
                  <!--                  @click="endExam"-->
                  <!--              >-->
                  <!--                Funga Mtihani-->
                  <!--              </v-btn>-->
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </template>
    </v-col>
    <ConfirmationDialog
      :ConfirmationDialog="confirmationDialog"
      @close="confirmationDialog = false"
    />
  </v-row>
</template>

<script>
import ConfirmationDialog from "./ConfirmationDialog.vue";
import staffProfile from "../../../graphql/StaffProfile/staffProfile.graphql";
import onDateStartedExamination from "../../../graphql/Examinations/onDateStartedExamination.graphql";
import ReconnectingWebSocket from "reconnecting-websocket";
import allExaminationPermissions from "../../../graphql/Permission/allExaminationPermissions.graphql";
import Vue from "vue";

export default {
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      dialog: false,
      e6: 1,
      e1: 1,
      e7: 1,
      timerEnabled: false,
      timerCount: 180,
      snackbar: false,
      text: "The test has ended and has been submited, you will now be redirected to your Dashboard",
      timeout: 5000,
      examEnded: false,
      examinationsDetails: null,
      verificationAllowedStatus: false,
      examHasStarted: false,
      websocket: null,
      websocketWebRTC: null,
      venue: null,
      examinationStage: null,
      confirmationDialog: false,
      myTimetables: [],
      timetable: null,
      roomSelectionContainer: false,
      videoChatContainer: false,
      localVideoComponent: null,
      remoteVideoComponents: [],
      candidatePermissionRequests: [],
      streamIDs: [],
      url: null,
      ws_schema: null,
      mediaConstraints: {
        video: { width: 1280, height: 720 },
      },
      localStream: null,
      remoteStream: null,
      isRoomCreator: false,
      rtcPeerConnection: null,
      timetableName: null,
      iceServers: {
        iceServers: [
          {
            urls: process.env.VUE_APP_TURN_URL,
            username: process.env.VUE_APP_TURN_USERNAME,
            credential: process.env.VUE_APP_TURN_PASSWORD,
          },
        ],
      },
      headers: [
        { text: "Candidate's Name", value: "fullName" },
        { text: "Count", value: "requestCount" },
        { text: "Grant", value: "bookingNumber" },
      ],
    };
  },
  methods: {
    allowVerification() {
      // console.log("Allowing Verification")

      this.websocket.send(
        JSON.stringify({
          authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
          venue: this.venue,
          event: "allowVerification",
        })
      );
    },
    initiateStartExam() {
      // console.log("Allowing Start Exam")
      this.examHasStarted = true;
      this.websocket.send(
        JSON.stringify({
          authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
          venue: this.venue,
          event: "startExamination",
        })
      );
    },
    endExam() {
      // console.log("Allowing Start Exam")
      this.confirmationDialog = true;
      this.websocket.send(
        JSON.stringify({
          venue: this.venue,
          authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
          event: "endExam",
        })
      );
    },
    startExam() {},
    play() {
      this.timerEnabled = true;
    },
    pause() {
      this.timerEnabled = false;
    },
    pad(n) {
      return n < 10 ? "0" + n : n;
    },
    startCameraInvigilation() {
      this.roomJoinFunction();
    },
    async roomJoinFunction() {
      await this.setLocalStream(this.mediaConstraints);
      // startVideoCallWithOffer();
    },
    async startVideoCallWithOffer() {
      // SOCKET EVENT CALLBACKS =====================================================
      console.log("Socket event callback: start_call");

      // if (isRoomCreator) {
      //   rtcPeerConnection = new RTCPeerConnection(iceServers)
      //   addLocalTracks(rtcPeerConnection)
      //   rtcPeerConnection.ontrack = setRemoteStream
      //   rtcPeerConnection.onicecandidate = sendIceCandidate
      //   await createOffer(rtcPeerConnection)
      // }
    },
    async startAnswer(offer_contents) {
      console.log("Socket event callback: webrtc_offer");
      this.rtcPeerConnection = new RTCPeerConnection(this.iceServers);
      console.log("These are RTCPeerConnection", this.rtcPeerConnection);
      this.addLocalTracks(this.rtcPeerConnection);
      this.rtcPeerConnection.ontrack = this.setRemoteStream;
      this.rtcPeerConnection.onicecandidate = this.sendIceCandidate;
      await this.rtcPeerConnection.setRemoteDescription(
        new RTCSessionDescription(offer_contents)
      );
      await this.createAnswer(this.rtcPeerConnection);
    },
    startSetRemoteDescription(answer_contents) {
      console.log("Socket event callback: webrtc_answer");
      this.rtcPeerConnection.setRemoteDescription(
        new RTCSessionDescription(answer_contents)
      );
    },
    setRemoteCandidate(candidateData) {
      console.log("Socket event callback: webrtc_ice_candidate");

      // ICE candidate configuration.
      let candidate = new RTCIceCandidate({
        sdpMLineIndex: candidateData.sdpMLineIndex,
        candidate: candidateData.candidate,
      });
      this.rtcPeerConnection.addIceCandidate(candidate);
    },
    async setLocalStream(mediaConstraints) {
      this.localVideoComponent = document.getElementById("local-video");
      let stream;
      try {
        stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      } catch (error) {
        console.error("Could not get user media", error);
      }
      this.localStream = stream;
      this.localVideoComponent.srcObject = stream;
    },
    addLocalTracks(rtcPeerConnection) {
      this.localStream.getTracks().forEach((track) => {
        rtcPeerConnection.addTrack(track, this.localStream);
      });
    },
    async createOffer(rtcPeerConnection) {
      let sessionDescription;
      try {
        sessionDescription = await rtcPeerConnection.createOffer();
        await rtcPeerConnection.setLocalDescription(sessionDescription);
      } catch (error) {
        console.error(error);
      }

      let offer_data = JSON.stringify({
        event: "offerevent",
        offer_creator: 12,
        offer_receiver: this.venue,
        offer_contents: sessionDescription,
        call_id: "2",
      });
      this.websocketWebRTC.send(offer_data);
    },
    async createAnswer(rtcPeerConnection) {
      let sessionDescription;
      try {
        sessionDescription = await rtcPeerConnection.createAnswer();
        await rtcPeerConnection.setLocalDescription(sessionDescription);
      } catch (error) {
        console.error(error);
      }

      let answer_data = JSON.stringify({
        event: "answerevent",
        answer_creator: this.venue,
        answer_receiver: "client1",
        answer_contents: sessionDescription,
        call_id: "2",
      });

      this.websocketWebRTC.send(answer_data);
    },
    setRemoteStream(event) {
      console.log(event.streams[0].id);
      let videoOldExistingElement = document.getElementById(
        event.streams[0].id
      );
      if (videoOldExistingElement != null) {
        //If Stream Already Existing
        console.log("Re-Setting Stream");
        videoOldExistingElement.srcObject = event.streams[0];
      } else {
        let colmdElement = document.createElement("div");
        colmdElement.setAttribute("class", "col-md-3");
        let video = document.createElement("video");
        video.setAttribute("class", "remote-video");
        video.setAttribute("autoplay", "autoplay");
        video.setAttribute("id", event.streams[0].id);

        console.log("Setting Stream");
        video.srcObject = event.streams[0];
        // remoteVideoComponents.srcObject = event.streams[0]
        // remoteStream = event.stream
        colmdElement.appendChild(video);
        // this.streamIDs.push(event.streams[0].id)
        document
          .getElementById("video-chat-container")
          .appendChild(colmdElement);
        // this.remoteVideoComponents.push(colmdElement)
      }
    },
    async sendIceCandidate(event) {
      if (event.candidate) {
        console.log("CANDIDATEEEeeeee22222222222222222222222222");
        let candidate_data = JSON.stringify({
          event: "candidateevent",
          candidate_creator: this.venue,
          candidate_receiver: "pc1",
          candidate_contents: event.candidate,
          call_id: "2",
        });
        await this.websocketWebRTC.send(candidate_data);
      }
    },
    queryCandidate() {
      console.log(this.timetableName);

      this.$apollo
        .query({
          query: allExaminationPermissions,
          variables: {
            permissionExamination_TimetableName: this.timetableName,
          },
        })
        .then((response) => {
          console.log(response.data.allExaminationPermissions.edges);
          this.candidatePermissionRequests = [];
          response.data.allExaminationPermissions.edges.forEach(
            (singleRequest) => {
              let proccessedData = {
                fullName:
                  singleRequest.node.permissionCandidate.candidatesUserId
                    .firstName +
                  " " +
                  singleRequest.node.permissionCandidate.candidatesUserId
                    .lastName,
                bookingNumber: singleRequest.node.permissionBookingNumber,
                requestCount: singleRequest.node.permissionRequestCount,
              };
              this.$store.commit(
                "AddCandidatePermissionRequests",
                proccessedData
              );
              this.candidatePermissionRequests.push(proccessedData);
            }
          );
        });
    },
    grantDenyPermission(item, permissionValue) {
      this.$store.commit("removeCandidatePermissionRequest", item);
      console.log(item, permissionValue);
      this.websocket.send(
        JSON.stringify({
          permissionGranted: permissionValue,
          bookingNumber: item.bookingNumber,
          authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
          event: "grantPermission",
        })
      );
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            // this.timerCount--;
          }, 1000);
        } else {
          this.dialog = false;
          this.snackbar = true;
          if (!this.examEnded) {
            this.timerCount = 5;
          }
          this.examEnded = true;
        }

        if (this.examEnded && this.timerCount <= 0) {
          window.location.href = "/latradts_canditate/booking";
        }
      },
      immediate: false, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    let self = this;
    // const ws_scheme = window.location.protocol === "https:" ? "wss" : "ws"
    this.ws_schema = window.location.protocol === "https:" ? "wss" : "ws";
    // alert(window.location.href.split('/')[2])
    // this.$store.dispatch('LoadTimetables')
    //   console.log(window.location.origin)
    this.$apollo
      .query({
        query: staffProfile,
        variables: {
          staffUser: this.$store.state.AuthStore.user.id,
        },
      })
      .then((response) => {
        response.data.staffProfile.results[0].staffVenue.timetableVenue.forEach(
          (timetable) => {
            this.myTimetables.push({
              relayId: timetable.id,
              key: timetable.primaryKey,
              unique_id: timetable.timetableUniqueId,
              name: timetable.timetableName,
              date: timetable.timetableDate,
              time: timetable.timetableTime,
            });
            this.venue =
              response.data.staffProfile.results[0].staffVenue.venuesUniqueId;

            // this.roomName=response.data.staffProfile[0].node.staffVenue.venuesUniqueId
            this.$apollo
              .query({
                query: onDateStartedExamination,
                variables: {
                  venueUniqueId: this.venue,
                },
              })
              .then((response) => {
                this.examHasStarted =
                  response.data.onDateStartedExamination.onDateExaminationIsStarted;
                this.verificationAllowedStatus =
                  response.data.onDateStartedExamination.onDateExaminationIsVerificationAllowed;
                this.timetableName =
                  response.data.onDateStartedExamination.onDateExaminationTimetable.timetableName;
                this.queryCandidate();
              });
            if (this.venue) {
              this.websocket = new ReconnectingWebSocket(
                process.env.VUE_APP_WEBSOCKET_URL + this.venue + "/"
              );
              // this.websocket = new ReconnectingWebSocket(this.ws_schema+"://latradts.mikutano.co.tz:8090/ws/exam/socket/"+this.venue+"/")
              // this.websocketWebRTC = new ReconnectingWebSocket(this.ws_schema+"://latradts.mikutano.co.tz:8090/ws/video_chat/"+this.venue+"/")
              // this.websocket = new ReconnectingWebSocket(this.ws_schema+"://172.17.17.116:8000/ws/exam/socket/"+this.venue+"/")
              // this.websocketWebRTC = new ReconnectingWebSocket(this.ws_schema+"://172.17.17.116:8000/ws/video_chat/"+this.venue+"/")
              // console.log(this.websocket)

              this.websocket.onmessage = function (event) {
                const data = JSON.parse(event.data);
                if (data.event.type === "invigilator_remaining_time") {
                  if (data.event.message) {
                    self.examinationsDetails = data.event.message;
                    self.examinationsDetails.forEach((singleExamination) => {
                      let days = Math.floor(
                        singleExamination.remaining_time / 24 / 60 / 60
                      );
                      let hoursLeft = Math.floor(
                        singleExamination.remaining_time - days * 86400
                      );
                      let hours = Math.floor(hoursLeft / 3600);
                      let minutesLeft = Math.floor(hoursLeft - hours * 3600);
                      let minutes = Math.floor(minutesLeft / 60);
                      // let remainingSeconds = singleExamination.remaining_time % 60;

                      // singleExamination.remaining_time=self.pad(hours) + ":" + self.pad(minutes);
                      if (parseInt(hours) !== 0) {
                        if (parseInt(hours) >= 0)
                          singleExamination.remaining_time =
                            "Masaa " +
                            parseInt(self.pad(hours)) +
                            " na dakika " +
                            parseInt(self.pad(minutes));
                        else
                          singleExamination.remaining_time =
                            "Lisaa " +
                            parseInt(self.pad(hours)) +
                            " na dakika " +
                            parseInt(self.pad(minutes));
                      } else {
                        singleExamination.remaining_time =
                          "Dakika " + parseInt(self.pad(minutes));
                      }
                    });
                  }
                } else if (
                  data.event.type === "allow_fingerprint_verification"
                ) {
                  if (data.event.code === 9300) {
                    self.verificationAllowedStatus = true;
                  } else if (data.event.code === 9304) {
                    console.log("No Examination scheduled for Now");
                    Vue.swal({
                      position: "center",
                      title: "404!",
                      text: "There is no Examination schedule for now",
                      timerProgressBar: true,
                      timer: 10000,
                      icon: "error",
                      showConfirmButton: true,
                      animation: false,
                    });
                  }
                } else if (data.event.type === "requestPermission") {
                  console.log(data.event.type);
                  self.queryCandidate();
                }
                if (data.event.type === "invigilator_grant_permission") {
                  self.queryCandidate();
                }
              };
              // Begin of Commented this to stop video conference
              // this.websocketWebRTC.onmessage = function (e) {
              //   const data = JSON.parse(e.data);
              //   if (data["event"]["event"] === "offerevent") {
              //     let offer_contents = data["event"]["offer_contents"];
              //     console.log(
              //       "offer_contents==========================================================="
              //     );
              //     console.log(offer_contents);
              //     self.startAnswer(offer_contents);
              //   } else if (
              //     data["event"]["event"] === "answerevent" &&
              //     data["event"]["answer_receiver"] === "ererere"
              //   ) {
              //     console.log(
              //       "answer_contents==========================================================="
              //     );
              //     let answer_contents = data["event"]["answer_contents"];
              //     console.log(answer_contents);
              //     self.startSetRemoteDescription(answer_contents);
              //   } else if (data["event"]["event"] === "candidateevent") {
              //     console.log(
              //       "candidate_contents==========================================================="
              //     );
              //     let candidate_contents = data["event"]["candidate_contents"];
              //     console.log(candidate_contents);
              //     self.setRemoteCandidate(candidate_contents);
              //   }
              // };
              // End of Commented this to stop video conference


              this.websocket.onopen = function () {
                // console.log(event)
                console.log(
                  "Successfully connected to the echo websocket server..."
                );
                clearInterval(self.countdownTimer);
                self.countdownTimer = setInterval(() => {
                  self.websocket.send(
                    JSON.stringify({
                      venue_unique_id: self.venue,
                      authToken: JSON.parse(
                        localStorage.getItem("latra-dts-token")
                      ),
                      event: "invigilator_remaining_time",
                    })
                  );
                }, 5000);
              };
              this.websocketWebRTC.onopen = function () {
                console.log(
                  "Successfully connected to the webrtc websocket server..."
                );
                // Begin of Commented this to stop video conference
                // self.roomJoinFunction();
                // End of Commented this to stop video conference
              };
            }
          }
        );
      });
  },
};
</script>

<style>
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
video {
  border-radius: 12px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
