<template>
  <v-container fluid class="pa-0">
      <SystemToolBar/>
      <router-view/>
  </v-container>
</template>

<script>
import SystemToolBar from './system_settings/shared/SystemToolBar.vue'
export default {
  components:{
    SystemToolBar
  }
}
</script>

<style>

</style>