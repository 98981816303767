<template>
<v-container fluid>
     <v-card>
        <v-card-title>  
          <v-btn  color="primary">Candidate Report</v-btn>
          <v-divider class="mx-4" color="orange " inset vertical ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
    <v-data-table
      :expanded.sync="expanded"
      show-expand
      :headers="headers"
      :items="this.$store.state.ReportStore.candidateReport"
      item-key="candidateLastName"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:body.append>
        <tr>
          <td></td>
          <td>
            <v-text-field
              type="number"
              label="Less than"
            ></v-text-field>
          </td>
          <td colspan="4"></td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
         <ul>
        <li v-for="examination in item.Examination" :key="`examination`+examination.resultsBooking">
          <span>Examination: {{examination.resultsExamination}}</span>,&nbsp;
          <span>Booking Number: {{examination.resultsBooking}}</span>,&nbsp;
          <span>Remarks: {{examination.resultsType}}</span>,&nbsp;
          <span>Marks: {{examination.resultsMarksAttained}}</span>&nbsp;
        </li>
        </ul>
      </td>
    </template>
    </v-data-table>
     </v-card>
</v-container>
</template>
<script>
  export default {
    data () {
      return {
        search: '',
        expanded: [],
      }
    },
    mounted () {
      this.$store.dispatch('AllCandidateReport')
    },

    computed: {
      headers () {
        return [
          {
            text: 'Candidate Name',
            align: 'start',
            sortable: false,
            value: 'candidateFirstName',
          },
          { text: 'Total Bookings',value: 'candidateTotalBookings'},
          { text: 'Total Cancelled Bookings', value: 'CancelledBookings',
           filter: value => {
              if (!this.candidateTotalBookings) return true

              return value < parseInt(this.candidateTotalBookings)
            }, },
          { text: 'Total Examinations', value: 'candidateTotalExaminations' },
          { text: 'Average Result', value: 'candidateAverageResult' },
          
        ]
      },

      examination: function () {
        return this.$store.state.ReportStore.candidateReport
      }
    },
    methods: {
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
    },
  }
</script>