var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","sm":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"dark":"","fab":"","small":"","color":"#EB8822"},on:{"click":_vm.backToCategory}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Back to Dashboard")])]),_c('v-btn',{staticClass:"rounded-xl",attrs:{"color":"primary","download":"","href":"https://dts.latra.go.tz:4443/latradts_media/questions_bank.xlsx"}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v("Download Template")],1),_c('v-btn',{staticClass:"mx-2 rounded-xl font-weight-medium",attrs:{"color":"#EB8822","dark":""},on:{"click":function($event){$event.stopPropagation();_vm.createQuestionCategory = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Question Category ")],1)],1)],1),_c('CreateCategory',{staticClass:"mb-5 ml-0",attrs:{"CreateQuestionCategory":_vm.createQuestionCategory},on:{"close":function($event){_vm.createQuestionCategory=false}}}),_c('v-card',[_c('v-card-title',[_c('v-toolbar-title',{staticClass:"text-h6"},[_vm._v("Question Category")]),_c('v-divider',{staticClass:"mx-4",attrs:{"color":"orange ","inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.questionCategores,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","fab":"","small":"","color":"orange darken-2"},on:{"click":function($event){return _vm.navigateToQuestions(item.actions,item.name,item.key,item.relayId)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","fab":"","small":"","color":"primary"},on:{"click":function($event){_vm.showEditCategoryForm=true;  _vm.categoryName=item.name;_vm.categoryIsActive=item.active_status;_vm.categoryUniqueId=item.actions}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","dark":"","fab":"","small":""},on:{"click":function($event){_vm.showAddQuestionForm=true; _vm.createDialog=true; _vm.categoryName=item.name;_vm.categoryUniqueId=item.key}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1)]}},{key:"item.active_status",fn:function(ref){
var item = ref.item;
return [(item.active_status)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"dark":"","color":"success","depressed":"","rounded":"","x-small":""}},[_vm._v(" "+_vm._s(item.active_status)+" ")]):_c('v-btn',{staticClass:"text-capitalize",attrs:{"dark":"","color":"error","depressed":"","rounded":"","x-small":""}},[_vm._v(" "+_vm._s(item.active_status)+" ")])]}}])})],1),_c('EditCategory',{attrs:{"editCategoryVisible":_vm.showEditCategoryForm,"categoryIsActive":_vm.categoryIsActive,"categoryUniqueId":_vm.categoryUniqueId,"categoryName":_vm.categoryName},on:{"closeEdit":function($event){_vm.showEditCategoryForm=false}}}),_c('NewQuestionForm',{attrs:{"AddQuestionVisible":_vm.showAddQuestionForm,"categoryId":_vm.categoryUniqueId,"createDialog":_vm.createDialog,"questions":_vm.question},on:{"close":function($event){_vm.createDialog=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }