import {apolloClient} from '@/vue-apollo'
import allExaminationSettings from '../../graphql/Examinations/allExaminationSettings.gql'
import addNewExaminationSettings from "../../graphql/Examinations/addNewExaminationSettings.gql";
import updateExaminationSetting from "../../graphql/Examinations/updateExaminationSettings.gql";
import Vue from "vue";

export default {
    state: {
        allExaminationSettings:[]
    },
    mutations: {
        addExaminationSettings(state, data){
            state.allExaminationSettings.push(data)
        },
        updateExaminationSettings(state, data) {
             state.allExaminationSettings.find(({key}) => key === data.key).name=data.name
             state.allExaminationSettings.find(({key}) => key === data.key).edit=data.edit
             state.allExaminationSettings.find(({key}) => key === data.key).duration=data.duration
             state.allExaminationSettings.find(({key}) => key === data.key).opq=data.opq
             state.allExaminationSettings.find(({key}) => key === data.key).sections=data.sections
             state.allExaminationSettings.find(({key}) => key === data.key).questions=data.questions
             state.allExaminationSettings.find(({key}) => key === data.key).flow=data.flow
        },
        clearExaminationSettings(state){
            state.allExaminationSettings=[]
        }
    },
    actions: {
        async LoadExaminationSettings(context){
            context.commit('clearExaminationSettings')

            apolloClient.query({
                query:allExaminationSettings
            }).then((response)=>{

                // console.log(response.data.allExaminationSettings.edges)
                response.data.allExaminationSettings.edges.forEach(element=>{
                    let sections=[]
                    let numberOfQuestion=0
                    element.node.catsectionExaminationSetting.edges.forEach(section=>{
                        let categoriesContent=[]
                        categoriesContent.push({
                            CatSectionUniqueId:section.node.catsectionUniqueId,
                            noq: section.node.catsectionNumberOfQuestions,
                            name: section.node.catsectionCategory.categoryName,
                            primaryKey:section.node.catsectionCategory.primaryKey
                        })
                        numberOfQuestion+=parseInt(section.node.catsectionNumberOfQuestions)

                        sections.push({
                                sectionName:section.node.catsectionSection.sectionName,
                                primaryKey:section.node.catsectionSection.primaryKey,
                                categories:categoriesContent
                        })
                    })
                    let data={
                        key:element.node.primaryKey,
                        unique_id:element.node.settingUniqueId,
                        name:element.node.settingName,
                        edit:element.node.settingUniqueId,
                        duration:element.node.settingDuration,
                        opq:element.node.settingNumberOfOptionsPerQuestion,
                        sections:sections,
                        questions:numberOfQuestion,
                        flow:element.node.settingExaminationFlow,
                    }
                    context.commit('addExaminationSettings',data)
                })

            })
        },
        async addNewExaminationSettings(context,data){
            apolloClient.mutate({
                mutation:addNewExaminationSettings,
                variables:data
            }).then((response)=>{

                console.log(response.data.addNewExaminationSettings.examinationSetting)
                let sections=[]
                let numberOfQuestion=0
                response.data.addNewExaminationSettings.examinationSetting.catsectionExaminationSetting.edges.forEach(section=>{
                    let categoriesContent=[]
                    categoriesContent.push({
                        CatSectionUniqueId:section.node.catsectionUniqueId,
                        noq: section.node.catsectionNumberOfQuestions,
                        name: section.node.catsectionCategory.categoryName,
                        primaryKey:section.node.catsectionCategory.primaryKey
                    })
                    numberOfQuestion+=parseInt(section.node.catsectionNumberOfQuestions)

                    sections.push({
                        sectionName:section.node.catsectionSection.sectionName,
                        primaryKey:section.node.catsectionSection.primaryKey,
                        categories:categoriesContent
                    })
                })
                let data={
                    key:response.data.addNewExaminationSettings.examinationSetting.primaryKey,
                    unique_id:response.data.addNewExaminationSettings.examinationSetting.settingUniqueId,
                    name:response.data.addNewExaminationSettings.examinationSetting.settingName,
                    edit:response.data.addNewExaminationSettings.examinationSetting.settingUniqueId,
                    duration:response.data.addNewExaminationSettings.examinationSetting.settingDuration,
                    opq:response.data.addNewExaminationSettings.examinationSetting.settingNumberOfOptionsPerQuestion,
                    sections:sections,
                    questions:numberOfQuestion,
                    flow:response.data.addNewExaminationSettings.examinationSetting.settingExaminationFlow,
                }
                context.commit('addExaminationSettings',data)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Examination Setting created Succesfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! An error Occurred',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },
        async updateExaminationSetting(context,data){
            console.log("Hii ni Action")
            apolloClient.mutate({
                mutation:updateExaminationSetting,
                variables:data
            }).then((response)=>{
                console.log(response.data.updateExaminationSettings.updatedSettings)
                let sections=[]
                let numberOfQuestion=0
                response.data.updateExaminationSettings.updatedSettings.catsectionExaminationSetting.edges.forEach(section=>{
                    let categoriesContent=[]
                    categoriesContent.push({
                        CatSectionUniqueId:section.node.catsectionUniqueId,
                        noq: section.node.catsectionNumberOfQuestions,
                        name: section.node.catsectionCategory.categoryName,
                        primaryKey:section.node.catsectionCategory.primaryKey
                    })
                    numberOfQuestion+=parseInt(section.node.catsectionNumberOfQuestions)

                    sections.push({
                        sectionName:section.node.catsectionSection.sectionName,
                        primaryKey:section.node.catsectionSection.primaryKey,
                        categories:categoriesContent
                    })
                })
                let data={
                    key:response.data.updateExaminationSettings.updatedSettings.primaryKey,
                    unique_id:response.data.updateExaminationSettings.updatedSettings.settingUniqueId,
                    name:response.data.updateExaminationSettings.updatedSettings.settingName,
                    edit:response.data.updateExaminationSettings.updatedSettings.settingUniqueId,
                    duration:response.data.updateExaminationSettings.updatedSettings.settingDuration,
                    opq:response.data.updateExaminationSettings.updatedSettings.settingNumberOfOptionsPerQuestion,
                    sections:sections,
                    questions:numberOfQuestion,
                    flow:response.data.updateExaminationSettings.updatedSettings.settingExaminationFlow,
                }
                context.commit('updateExaminationSettings',data)
                if(!response.errors){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Examination Setting Updated Succesfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {

                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! An error Occured',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },

    }
}