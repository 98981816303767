import InvigilatorMain from './InvigilatorMain.vue'
import InvigilatorExamination from './invigilator_examination/InvigilatorExamination.vue'

export default [
    {
        path: '/latradts_invigilator',
        name:'InvigilatorMain',
        component: InvigilatorMain,
        meta: { requiresAuth: true },
        children:[
            {
                path: 'exam',
                name:'InvigilatorExamination',
                component: InvigilatorExamination,
                meta: {
                    permissions: ['can_invigilate_exam']
                }
            },
        ]
     },    
]