<template>
<v-container fluid>
     <v-card>
        <v-card-title>
          <v-btn  color="primary">Session Report</v-btn>
          <v-divider class="mx-4" color="orange " inset vertical ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
    <v-data-table
      :headers="headers"
      :items="this.$store.state.ReportStore.examinationSession"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:body.append>
        <tr>
          <td></td>
          <td>
            <v-text-field
              v-model="calories"
              type="number"
              label="Less than"
            ></v-text-field>
          </td>
          <td colspan="4"></td>
        </tr>
      </template>
    </v-data-table>
     </v-card>
</v-container>
</template>

<script>
  export default {
    data () {
      return {
        search: '',
      }
    },
    mounted() {
      this.$store.dispatch('AllSession')
      
    },
    computed: {
      headers () {
        return [
          {
            text: 'Session Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Session Candidates', value: 'candidates' },
          { text: 'Session Examination', value: 'examination' },
          {
            text: 'Session Bookings',
            value: 'Bookings',
          },
          { text: 'Cancelled Bookings', value: 'cancelledBookings' },
          { text: 'Session Abscondents', value: 'abscondents' },
          { text: 'Session Slots', value: 'slots' },
          { text: 'Session Time', value: 'time' },
          
        ]
      },

    },
    methods: {
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
    },
  }
</script>