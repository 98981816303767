import {apolloClient} from '@/vue-apollo'
import allQuestionCharts from '../../graphql/QuestionCharts/allQuestionCharts.gql'

export default {
    state: {
        questionCharts: []
    },
    
    mutations: {
        getQuestionCharts(state,data){
            state.questionCharts.push(data)
        },
        clearQuestionCharts(state){
            state.questionCharts=[]
        }
    },
    actions: {
        async QuestionCharts(context) {
            context.commit('clearQuestionCharts')

            apolloClient.query({query:allQuestionCharts}).then((response) => {
                    var questionsCategoryFrequency = [
                        
                    ];
                    questionsCategoryFrequency = response.data.questionsBankStatistics;
                    var difficultLevel=[
                        {"code":"es","description":"Easy"},
                        {"code":"md","description":"Medium"},
                        {"code":"hd","description":"Hard"},
                        {"code":"xd","description":"Extra Hard"},
                    ]
                    JSON.parse("["+questionsCategoryFrequency[0].questionsDifficultiesFrequency.replace("[","").replace("]","").replaceAll("'","\"")+"]").forEach(element=>{
                        let difficult;
                        difficultLevel.forEach(level=>{
                            if(level.code.toLowerCase() ===element.question_difficulties.toLowerCase()){
                                difficult=level.description
                            }
                        })
                        let data={
                            name: difficult,
                            value: element.count,
                        }

                        context.commit('getQuestionCharts',data)
                    })
                })
            console.log("happynessqqq");

        },
      },
    }