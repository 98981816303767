<template>
  <v-row justify="center">
    <v-dialog
      v-model="createExaminationSettingDialog"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-plus-circle</v-icon> Create Examination Setting</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="settingName"
                        :counter="30"
                        label="Setting Name *"
                        required
                        prepend-icon="mdi-cog"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="Duration"
                        :counter="30"
                        label="Duration in hours *"
                        required
                        prepend-icon="mdi-clock"
                        type="number"
                    >
                    </v-text-field>

                    <v-text-field
                        v-model="NumberOfOptionPerQuestion"
                        label="Number of options per question *"
                        required
                        type="number"
                        prepend-icon="mdi-multicast"
                    >
                    </v-text-field>
                      <v-select
                        v-model="ExaminationFlow"
                        :items="flow"
                        prepend-icon="mdi-chart-timeline-variant-shimmer"
                        attach
                        item-text="name"
                        item-value="code"
                        chips
                        label="Select Examination Flow"
                      >
                      </v-select>
                      <v-combobox
                          v-model="ExaminationSections"
                          :items="$store.state.ExaminationSectionStore.allExaminationSections"
                          chips
                          item-text="sectionName"
                          label="Examination Sections *"
                          multiple
                          prepend-icon="mdi-select"
                          required
                          @change="categoryShow"
                      >
                      </v-combobox>
                    </v-col>

                      <v-col
                          cols="12"
                          sm="12"
                          md="6"
                          v-for="section in SelectedExaminationSections"
                          :key="section.unique_id"
                        >
                          <div
                          class="text-h5"
                          >
                         Section {{section.sectionName}}</div>
                        <v-combobox
                        :items="$store.state.CategoriesStore.categorylist"
                        item-text="name"
                        label="Category"
                        chips
                        multiple
                        @change="addQuestionNoField(section,$event)"
                        >

                        </v-combobox>
                        <v-row>
                          <v-col
                              v-for="item in ExaminationSectionsContent.find(({primaryKey}) => primaryKey === section.key).category"
                              :key="item.actions"
                              cols="12"
                              md="6"
                              sm="6"
                          >
                            <v-text-field
                                cols="12"
                                dense
                                :label="item.name"
                                md="6"
                                sm="6"
                                type="number"
                                @change="setQuestionNumber(section.key,item,$event)"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>


                      </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"

           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="#EB8822"
            fab
            small
            class="white--text"
           @click="CreateExaminationSetting"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <QuestionCategoryModal @updatedQuestionsAndCategories="receiveUpdatedQuestionsAndCategories" :updatedQuestionsAndCategories="receiveUpdatedQuestionsAndCategories"/>
  </v-row>
</template>


<script>
import allRegions from '../../../../graphql/VenuesAdmin/allRegions.gql'
import QuestionCategoryModal from './QuestionCategoryModal.vue'

export default {
    components:{
        QuestionCategoryModal,
    },
    data: () => ({
        checkbox:false,
        // openLocationDialog:false,
        opencategoryDialog:false,
        time: null,
        menu4:false,
        menu3: false,
        modal2: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,   
        Duration:'',
        NumberOfQuestions:'',
        ExaminationSections:'',
        SelectedExaminationSections:[],
        NumberOfOptionPerQuestion:'',
        ExaminationFlow:'',
        settingName:'',
        examinationOptions:[],
        ExaminationSectionsContent:[],
        createExaminationSettingDialog:false,
        regions:[], 
        nameRules: [
            v => !!v || 'Location name is required',
            v => v.length <= 30 || 'Location name must be less than 10 characters',
        ],
        Region: '',
        District: [],
        flow:[
            {'code':'SS', 'name':'Single Section'},
            {'code':'SQ', 'name':'Single Question'},
            {'code':'AQ', 'name':'All Questions'},
        ]
    }),
    props: ["CreateExaminationSettingDialog"],
    mounted(){
      this.$store.dispatch('loadCategoriesFromServer')
      this.$store.dispatch('LoadExaminationSections')
      this.$apollo.query({
        query:allRegions
      }).then((response)=>{
          response.data.allRegions.edges.forEach(element => {
            this.regions.push(element.node)
          });
      })
    },
    
    methods:{
      CreateExaminationSetting: function(){
        console.log(this.ExaminationSectionsContent)
        let data={
          settingDuration:this.Duration,
          settingExaminationFlow:this.ExaminationFlow,
          settingName:this.settingName,
          settingNumberOfOptionsPerQuestion:this.NumberOfOptionPerQuestion,
          sectionList:JSON.stringify(this.ExaminationSectionsContent),
        }
        this.$store.dispatch('addNewExaminationSettings',data).then(()=>{
          this.ExaminationSections=''
          this.NumberOfOptionPerQuestion=''
          this.ExaminationFlow=''
          this.settingName=''
          this.examinationOptions=[]
          this.SelectedExaminationSections=[]
          this.ExaminationSectionsContent=[]
          this.date=''
          this.time=''
          this.Duration=''
          this.$emit('close')

        })
      },
      setQuestionNumber(unique,category,questionNumber){

        this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === unique).category.find(({key}) => key === category.key).questionNo=questionNumber

      },
      categoryShow:function(){
        this.SelectedExaminationSections=[]
        // this.ExaminationSectionsContent=[]
        this.ExaminationSections.forEach(element=>{
          this.SelectedExaminationSections.push(element)
          this.ExaminationSectionsContent.push({
            primaryKey:element.key,
            category:[]
          })
        })


        console.log(this.ExaminationSectionsContent)

        },
      openQuestionCategoryModel(){
        this.opencategoryDialog=true;
      },
      addQuestionNoField(section,value){
        console.log(section,value)
        this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === section.key).category=[]
        value.forEach(element=>{
          this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === section.key).category.push(element)
        })


      },
      receiveUpdatedQuestionsAndCategories(){
        // console.log(data);
      }
    },
  watch:{
    CreateExaminationSettingDialog:function(newvalue){
      this.createExaminationSettingDialog=newvalue
    }
  }
  }
</script>