<template>
  <v-row justify="center">
    <v-dialog
      v-model="OpenNewUserForm"
      persistent
      max-width="80%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-account-plus-outline</v-icon> Add New Person to DTS</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="firstName"
                        :counter="15"
                        label="First Name *"
                        required
                        prepend-icon="mdi-text-recognition"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="lastName"
                        :counter="15"
                        label="Last Name *"
                        required
                        prepend-icon="mdi-text-recognition"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="email"
                        :counter="10"
                        label="Email Address*"
                        required
                        prepend-icon="mdi-text-recognition"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phone"
                        :counter="10"
                        label="Phone number"
                        prepend-icon="mdi-text-recognition"
                        required
                        type="tel"
                    >
                    </v-text-field>
<!--                    <v-text-field-->
<!--                        v-model="password"-->
<!--                        label="Password*"-->
<!--                        required-->
<!--                        prepend-icon="mdi-text-recognition"-->
<!--                    >-->
<!--                    </v-text-field>-->
                    <v-combobox
                        v-model="testCenterVenue"
                        :items="$store.state.VenueStore.allVenues"
                        item-text="venueName"
                        item-value="venuesUniqueId"
                        label="Test Center"
                        prepend-icon="mdi-text-recognition"
                        required
                        chips
                    >
                    </v-combobox>
                        <template v-slot:label>
                            <div>Select <strong>Access Level</strong>
                            <v-btn icon color="dark" > <v-icon>mdi-information-outline</v-icon> </v-btn>
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click="showContextMenu =! showContextMenu"  v-bind="attrs" v-on="on" elevation="1" right fab x-small color="primary white--text" style="margin:5px"><v-icon>mdi-plus</v-icon> </v-btn>
                                </template>
                                <span>Add New Access Level</span>
                            </v-tooltip>
                            </div>
                        </template>
                          <v-radio-group v-model="userRole">
                            <v-radio v-for="(role,index) in allRolesFromDB" :key="index" :value="role.node.primaryKey">
                                <template v-slot:label>
                                <div>{{role.node.roleDescriptions}}</div>
                                </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="rounded-lg font-weight-bold px-4"
            outlined
           @click="$emit('close')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn
            color="#EB8822"
            
            class="white--text rounded-lg font-weight-bold px-4"
           @click="createUser()"
          >
            <v-icon small>mdi-check-all</v-icon> Create User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
// import register from '../../../../graphql/register.gql'
// import updateAfterRegister from '../../../../graphql/updateAfterRegister.gql'
// import gql from 'graphql-tag'
import allUserRoles from '../../../../graphql/allUserRoles.gql'

export default {
 
    components:{
    },
    data: () => ({
        checkbox:false,
        openLocationDialog:false,
        testCenterVenue:null,
        time: null,
        menu4:false,
        menu3: false,
        modal2: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,   
        firstName:'',   
        lastName:'',
        email:'',
        phone:null,
        allRolesFromDB:[],
        userRole:'',
        isCloned:false,
        password:'',
    }),
    props: ["OpenNewUserForm"],
    methods:{
      createUser:function(){
        let data={
          first:{
            username:this.email,
            password: this.password
          },
          second:{
            email:this.email,
            firstName:this.firstName,
            lastName:this.lastName,
            role:this.userRole,
            venue:this.testCenterVenue.primaryKey,
            staffPhone:this.phone
          }
        }
        this.$store.dispatch('AddUserAdmin',data).then(()=>{
          this.email=''
          this.firstName=''
          this.lastName=''
          this.userRole=''
          this.testCenterVenue=null
          this.phone=''
          this.$emit('close')
        })
      },
      
    },mounted(){
      this.$store.dispatch('LoadVenues')
      this.$apollo.query({
        query:allUserRoles,
      }).then((response)=>{
        this.allRolesFromDB=response.data.allRoles.edges
      })
      let charactersArray = 'a-z'.split(',')
        let CharacterSet = ''
        let password = ''
  
        
        charactersArray = 'a-z,A-Z,0-9,#'.split(',')
         if(charactersArray.indexOf('a-z') >= 0){
      CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
    } if(charactersArray.indexOf('A-Z') >= 0){
      CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    }

    if(charactersArray.indexOf('0-9') >= 0) {
      CharacterSet += '0123456789'
    } if(charactersArray.indexOf('#') >= 0) {
      CharacterSet += '![]{}()%&*$#^~@|'
    } for(let i = 0; i < 16; i++) {
      password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
    }
      this.password=password
  }
}
</script>