<template>
  <div class="text-center">
    <v-dialog
      v-model="opencategoryDialog"
      width="80%"
      
    >


      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Section Category
        </v-card-title>

        <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
                v-for="category in $store.state.CategoriesStore.categorylist"
                :key="category"
                >
                <v-checkbox
                    :label="category.name"
                    color="red"
                    :value="category.key"
                    @change="questionPerCategoryDialogyModel(category.key)"
                >
                </v-checkbox>
              </v-col>
            </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
            color="#EB8822"
            fab
            small
            class="white--text"
          >
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  <v-dialog
        v-model="questionPerCategoryDialog"
        max-width="290"
        persistent
      >
        <v-card>
          <v-card-title class="text-h6">
            Questions per Category {{questionsAndCategories}}
          </v-card-title>

          <v-card-text>
                  <v-text-field
                    label="Number Questions"
                    outlined
                    dense
                    v-model="questionsPerCategory"
                  ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="saveQuestionPerCategory"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
  </v-dialog>

  </div>
</template>

<script>
  export default {
      props:['opencategoryDialog'],
    data () {
      return {
        dialog: false,
        categorykey:'',
        questionsPerCategory:0,
        questionsAndCategories:[],
        questionPerCategoryDialog: false,
      }
    },
    methods: {
      questionPerCategoryDialogyModel(key){
        this.categorykey=key
        this.questionPerCategoryDialog=true
      },
      saveQuestionPerCategory(){
        this.questionsAndCategories.push({
          category:this.categorykey,
          questions:this.questionsPerCategory
        })
        this.questionPerCategoryDialog=true
        this.questionsPerCategory=0
        this.$emit('updatedQuestionsAndCategories',this.questionsAndCategories)
      }
    }
  }
</script>categorylist