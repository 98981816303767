<template>
  <v-container fluid >
      <Toolbar/>
      <router-view/>
  </v-container>
</template>

<script>
// import Toolbar from './candidate_shared/navigations/Toolbar.vue'

export default {
  components:{
    // Toolbar
  }
}
</script>

<style>

</style>