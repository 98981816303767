<template>
<v-container fluid>
    <v-flex style="padding:10px"  xs12 md9 sm6>
        <v-btn rounded small  @click="backToTimeTableSession" 
            color="orange">
            <span class="flex center" style="color:white"><v-icon>mdi-arrow-left</v-icon> Back  </span>
        </v-btn>
    </v-flex>

    <v-col>
        <v-row >
        <v-col sm="12" md="4" lg="3">
                <v-alert class="rounded-lg pa-5" elevation="2" border="left"
                icon="mdi-book-account"
                prominent
                colored-border
                color="success">
                    <v-row class="text-h3 d-flex justify-center">
                      {{booking}}
                    </v-row>
                    <v-row class="d-flex justify-center">
                        Total Booking Candidate
                    </v-row>
                </v-alert>
            </v-col>
            <v-col sm="12" md="4" lg="3">
                <v-alert class="rounded-lg pa-5" elevation="2" border="left"
                icon="mdi-book-marker"
                prominent
                colored-border
                color="cyan">
                    <v-row v-if="this.$store.state.BookingStore.bookinglist.length >0" class="text-h3 d-flex justify-center">
                      {{this.$store.state.BookingStore.bookinglist[0].slots}}
                    </v-row>
                    <v-row v-else class="text-h3 d-flex justify-center">
                      0
                    </v-row>
                    <v-row class="d-flex justify-center">
                          Total Slots 
                    </v-row>
                </v-alert>
            </v-col>
            
            <v-col sm="12" md="4" lg="3">
                <v-alert class="rounded-lg pa-5" elevation="2" border="left"
                icon="mdi-book-check"
                prominent
                colored-border
                color="warning">
                    <v-row class="text-h3 d-flex justify-center">
                  {{this.$store.state.BookingStore.bookinglist.length}}
                    </v-row>
                    <v-row class="d-flex justify-center">
                          Number of Slots Booked
                    </v-row>
                </v-alert>
            </v-col>

            <v-col sm="12" md="4" lg="3">
                <v-alert class="rounded-lg pa-5" elevation="2" border="left"
                icon="mdi-book-arrow-right"
                prominent
                colored-border
                color="info">
                    <v-row class="text-h3 d-flex justify-center">
                      {{slotsRemain}}
                    </v-row>
                    <v-row class="d-flex justify-center">
                          Number of Slots Remain
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
    </v-col>
    <v-divider></v-divider>
    <v-col>
      <v-card>
        <v-card-title>  
          <v-btn  color="primary">{{timetableName}}</v-btn>
          <v-divider class="mx-4" color="orange " inset vertical ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="$store.state.BookingStore.bookinglist"
          :search="search"
        >

      <template v-slot:item.actions="{item }">
          <v-list-item-action>
              <v-list-item-action-text >
              <v-menu  offset-y app>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn  slot="activator" color="indigo" icon v-bind="attrs" v-on="on" ><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list dense>
                      <v-list-item link  @click="openCandidateExaminationPreviewDialog(item)">
                          <v-list-item-title><v-icon color="indigo" style="height: 16px;font-size: 16px;" >mdi-eye
                            </v-icon> View Examination</v-list-item-title></v-list-item>
                      <v-divider></v-divider>
                  </v-list>
              </v-menu>                     
              </v-list-item-action-text>
          </v-list-item-action>
      </template>
    </v-data-table>
  </v-card>
</v-col>

 <v-dialog
      v-model="examPreviewDialog"
      max-width="900"
      persistent
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar 
          dark
          color="#EB8822"
        >
          
          <v-row>
            <v-col
            cols="12"
            class="d-flex justify-space-between"
            >
            <span>
              {{timetableName}}<br/>
              <span class="text-normal">{{candidateExaminationName}}</span>
            </span> 
         <v-btn
            color="error"
            class="rounded-xl"
            @click="examPreviewDialog = false"
          >
           <v-icon>mdi-close-circle</v-icon> Close
          </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        

        <v-card-text class="mt-10">
          <v-progress-circular
          v-if="!examinationLoaded"
      :size="70"
      :width="7"
      color="#EB8822"
      indeterminate
    ></v-progress-circular>
        <ul
        v-else
          v-for="(item, i) in candidateExamination"
          :key="i"
          style="color:black"
        >
            <p><b>{{(i+1)+`. `+item.questionDescription}}</b></p>
                <li v-for="(answerOption, i) in item.answerOptions" :key="`answeroptions`+i" class="ml-8">
                  <span
                    
                    v-for="attachment in item.questionAttachmentPath"
                    :key="attachment"
                    class="ml-4 mb-4"
                  >
                    <v-img
                      v-if="item.questionHasAttachment"
                      :src="attachment"
                      max-height="100px"
                      max-width="100px"
                      style="border-radius: 10px"
                    ></v-img>
                  </span>
                  <span>
                    <v-chip v-if="answerOption.answer==item.selectedAnswer">
                      {{answerOption.answer}}&nbsp;&nbsp;
                      <v-icon v-if="answerOption.answerIsCorrect" color="success">mdi-check-circle</v-icon>
                      <v-icon v-else color="error">mdi-close-circle</v-icon>
                      
                    </v-chip> 
                    <span v-else>{{answerOption.answer}}</span>
                  </span>


                  </li>
                  <br/>
        </ul>
        <p v-if="noExaminationToLoad&&examinationLoaded">No Examination</p>
        </v-card-text>

        <v-card-actions>

          <v-btn
          v-if="examinationLoaded"
            color="error"
            class="rounded-xl"
            @click="examPreviewDialog = false"
          >
           <v-icon>mdi-close-circle</v-icon> Close
          </v-btn>

          
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import allCandidateAnswerSheets from '../../../../graphql/CandidateBooking/allCandidateAnswerSheets.gql'
  export default {
    data () {
      return {
        search: '',
        loading: false,
        bookingListDetails: null,
        examPreviewDialog:false,
        candidateExamination:[],
        candidateExaminationName:null,
        examinationLoaded:false,
        noExaminationToLoad:true,
        selection: 1,
        headers: [
        { text: 'S/N ',
          align: 'start',
            filterable: false,
            value: 'serial_number' 
            },
          {
            text: 'Candidate Name',
            value: 'candidateName',
          },
          { text: 'Booking Number', value: 'number' },
          { text: 'Booking Date', value: 'booking_date' },
          { text: 'Time', value: 'time' },
          { text: 'Action', value: 'actions'},
        ],     
      }
    },

    mounted(){
        this.$store.dispatch('listAllBookingCandidate',this.$store.state.TimetableStore.selectedTimetableSession.relayId)
    },

    methods: {
      backToTimeTableSession(){
        this.$router.push({ path: '/latradts_settings/timetable'});
      },

        navigateToBookingList(timetableUniqueId,timetableName,primaryKey,relayId){
            var selected={ timetableUniqueId: timetableUniqueId,timetableName:timetableName,primaryKey:primaryKey,relayId:relayId }
            this.$store.dispatch('updateselectedTimetableSession',selected)
            this.$router.push({ name: 'ExaminationBookingList'})
        },
          reserve () {
    this.loading = true

    setTimeout(() => (this.loading = false), 2000)
    },
    openCandidateExaminationPreviewDialog(booking_item){
        this.examPreviewDialog=true
        this.examinationLoaded=false
        this.noExaminationToLoad = true

        this.$apollo.query({
          query:allCandidateAnswerSheets,
          variables:{
            answersheetBooking_BookingNumber:booking_item.number
          }
        }).then((response)=>{
          this.candidateExamination=[]
            this.candidateExaminationName=response.data.allCandidateAnswerSheets.edges[0].node.answersheetExamination.examinationTitle
          this.examinationLoaded=true
          response.data.allCandidateAnswerSheets.edges.forEach(questionAndAnswer => {
            if(this.noExaminationToLoad){
              this.noExaminationToLoad=false
            }
            let answerOptionsArray=[]
            questionAndAnswer.node.answersheetAnswer.answerQuestion.questionAnswerOptions.edges.forEach(answerOptions=>{
              answerOptionsArray.push({
                answer:answerOptions.node.answerDescriptions.trim(),
                answerIsCorrect:answerOptions.node.answerIsCorrect
              })
            })
            let questionDescription = questionAndAnswer.node.answersheetAnswer.answerQuestion.questionDescriptions
            let attachments = []

            questionAndAnswer?.node?.answersheetAnswer?.attachmentQuestion?.edges?.forEach(singleAttachment=>{
              attachments.push( process.env.VUE_APP_URL + singleAttachment.node.attachmentPathName)
            })
            let QuestionData={
              questionDescription:questionDescription,
              questionHasAttachment: questionAndAnswer.node.answersheetAnswer.hasAttachment,
              questionAttachmentPath: attachments,
              answerOptions:answerOptionsArray,
              selectedAnswer:questionAndAnswer.node.answersheetAnswer.answerDescriptions.trim()
            }
            this.candidateExamination.push(QuestionData)
            
          })
          console.log(this.candidateExamination)
          
        })
    }
    },
    computed:{
      timetableName(){
            return this.$store.state.TimetableStore.selectedTimetableSession.timetableName;
        },

      booking: function(){
        return this.$store.state.BookingStore.bookinglist.number;
      },

      slots(){
        if (this.$store.state.BookingStore.bookinglist.length >0){
          return   this.$store.state.BookingStore.bookinglist[0].slots;
        }else{
          return   []
        }
        },

        slott(){
          return this.$store.BookingStore.bookinglist.slots
        },
      
      slotsRemain(){
        if (this.$store.state.BookingStore.bookinglist.length >0){
          return  this.$store.state.BookingStore.bookinglist[0].slots-this.$store.state.BookingStore.bookinglist.length;
        }else{
          return   0
        }
        },
    }
  }
</script>