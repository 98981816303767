<template>
<v-banner outlined shaped type="">
      <v-chart class="chart" :option="option" />
</v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: "HelloWorld",
  props:['dataLabels'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "white"
  },
data() {
    return {
      option: {
  title: {
    text: 'Examination Result Performance',
    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      // radius: '50%',
      radius: ['40%', '70%'],
      data:this.dataLabels,
      emphasis: {
        itemStyle: {
           borderRadius: 10,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
},
computed: {
  
}
    }
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>




