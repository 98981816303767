<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md5>
                  <v-card class="elevation-12 pb-5">
                     <v-toolbar dark color="#EB8822">
                        <v-toolbar-title dense>LATRA - Reset Password</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form @submit.prevent="loginUser">
                           <v-text-field
                              prepend-inner-icon="mdi-email"
                              outlined
                              class="rounded-lg mx-5 mt-5"
                              v-model="username"
                              label="Email"
                              type="text"
                           ></v-text-field>
                     <v-card-actions class="px-4 justify-end">
                        <v-btn color="#EB8822" class="white--text rounded-lg mb-3 mx-1 auth-submit pa-5" @click="updatePassword" ><v-icon small>mdi-lock-open-outline</v-icon> Send Reset Password Link</v-btn>
                     </v-card-actions>
                        </v-form>
                     </v-card-text>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
// import { mapActions } from 'vuex'
export default {
   name: 'Login',
   props: {
      source: String,
   },
   data () {
    return {
        authDetails: {
            username: '',
            
            
        },
        password1:'',
        password2: '',
        passwordMatch:false,
        passwordErrorMessage:false
    }
   },
   methods: {
       confirmPassword:function() {
         if(this.password1.length>0&&this.password2.length>0){
             this.passwordErrorMessage=true
             if(this.password1==this.password2){
                this.passwordMatch=true;
             }else{
                 this.passwordMatch=false
             }
         }else{
             this.passwordErrorMessage=false
         } 
       },
    // ...mapActions(['login']),
    updatePassword: function () {
      this.login(this.authDetails)
        .then(() => this.$router.push('/'))
    }
  }
};
</script>

<style></style>
