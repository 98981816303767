<template>
<v-container fluid>
        <CreateTestCenterConfiguration v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_add_fingerprint_device_configuration')!==undefined" :CreateTestCenterConfigurationDialog="CreateTestCenterConfigurationDialog" @close="CreateTestCenterConfigurationDialog=false"/>
        <EditTestCenterConfiguration v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_edit_fingerprint_device_configuration')!==undefined" :EditTestCenterConfigurationDialog="EditTestCenterConfigurationDialog" :testCenterConfigurationsItems="testCenterConfigurationsItems" @close="EditTestCenterConfigurationDialog=false"/>
    <v-row>
        <v-tooltip bottom color="#EB8822">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_add_fingerprint_device_configuration')!==undefined" class="rounded-lg ml-5"  color="#EB8822 " dark  right style="margin:5px" v-bind="attrs" @click="CreateTestCenterConfigurationDialog =true" v-on="on">
                    <v-icon class="white--text" small>mdi-plus</v-icon> Add Fingerprint Configuration
                    </v-btn>
            </template>
            <span>Create Examination</span>
        </v-tooltip>
    </v-row>
    <v-row>
        <v-col sm="12"> 
            <div style="padding:10px">
                <template>
                    <v-card>
                        <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :items="$store.state.FingerprintDeviceConfigStore.allVenueDevices"
                        :search="search"
                        v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_fingerprint_device')!==undefined"
                        >
                        <template v-slot:item.edit="{ item }">
                            <v-btn v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_edit_fingerprint_device_configuration')!==undefined" color="primary" dark fab small @click="openDialog(item)" >
                                <v-icon  small>mdi-pen</v-icon>
                            </v-btn>
                        </template>
                          <template v-slot:item.region="{ item }">
                            <span>{{item.region.regionName}}</span>
                          </template>
                          <template v-slot:item.venue="{ item }">
                          {{item.venue.venuesName}}
                          </template>
                        </v-data-table>
                    </v-card>
                </template>
            </div>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import EditTestCenterConfiguration from './EditFingerprintDeviceConfiguration.vue'
import CreateTestCenterConfiguration from './AddFingerprintDeviceConfiguration.vue'

export default {
data:()=>({
    CreateTestCenterConfigurationDialog:false,
    EditTestCenterConfigurationDialog:false,
    examDuration:'',
    examNumberOfQuestions:'',
    examNumberOfSections:'',
    examNumberOfOptionPerQuestion:'',
    examExaminationFlow:'',
    examsettingName:'',
    examsettingUniqueId:'',
    testCenterConfigurationsItems:null,
    search: '',
    headers: [
        { text: 'Device Serial Number', value: 'deviceSerialNumber' },
        { text: 'Test Center Region', value: 'region' },
        { text: 'Test Center Venue', value: 'venue' },
        { text: 'Edit', value: 'edit' },
    ],
    testCenterConfigurations: [] 
}),
components:{
    CreateTestCenterConfiguration,
    EditTestCenterConfiguration,
},
mounted(){
    this.$store.dispatch('LoadVenueDevices').finally(()=>{

    })
},methods:{
    openDialog:function(value){
        this.testCenterConfigurationsItems=value
        this.EditTestCenterConfigurationDialog=true
    }
}
}
</script>

<style>

</style>