<template>
  <v-row justify="center">
    <v-dialog
      v-model="EditRegionDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Edit Examination Venue</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                          <v-text-field
                              v-model="regionName"
                              label="Region Name*"
                              required
                              prepend-icon="mdi-map-marker"
                          >
                          </v-text-field>
                          <v-switch
                              v-model="isActive"
                              :label="isActive? 'Active' :'Inactive'"
                              color="success"
                              hide-details
                          ></v-switch>
                        </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"

           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="#EB8822"
            fab
            small
            class="white--text"
           @click="EditRegion"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>


<script>

export default {
    components:{

    },
    data: () => ({
        regionName:'',
        regionUniqueId:'',
        isActive:false
    }),
    props: ["EditRegionDialog","EditRegionItems"],
    methods:{
      EditRegion: function(){
          let data={
            regionUniqueId:this.regionUniqueId,
            regionName:this.regionName,
            regionIsActive:this.isActive,
          }
          this.$store.dispatch('EditRegionsAction',data).then(()=>{
            this.$emit('close')
          })

      },
      consoleMethod(){
        console.log(this.venuesIsActive)
      }
    },
    watch:{
       EditRegionDialog:function(){
        console.log(this.EditRegionItems)
         this.regionUniqueId=this.EditRegionItems.regionUniqueId
         this.regionName=this.EditRegionItems.regionName
         this.isActive=this.EditRegionItems.regionIsActive
      }
    }
  }
</script>