<template>
    <v-card >
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant.sync="mini"
            app
            permanent
        >
            <v-list-item class="px-2">
            <v-list-item-avatar>
                <v-img  link src="/arm.png" to="/"></v-img>
            </v-list-item-avatar>

            <v-list-item-title link to="/">{{$store.state.AuthStore.user.firstName}} {{$store.state.AuthStore.user.lastName}}</v-list-item-title>

            <v-btn
                icon
                @click.stop="mini = !mini"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
            <v-list-item  v-for="link in links"  :key="link.name" :to="link.routinglink" link >
                <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list>
                <template v-slot:append>
                    <div class="pa-2">
                    <v-btn    color="indigo white--text" fab right style="margin:5px" x-small><v-icon>mdi-cog-outline</v-icon></v-btn>
                    <v-btn   color="blue white--text" fab right style="margin:5px" x-small><v-icon>mdi-account-tie</v-icon></v-btn>
                    <v-btn    color="pink white--text" fab right style="margin:5px" x-small><v-icon>mdi-power</v-icon></v-btn>
                    </div>
                </template>
        </v-navigation-drawer>
    </v-card>  
</template>

<script>

export default {
    props:['mini'],
    data(){
        return{
            drawer:true,
    links:[
        {
            name:'Candidate Dashboard',
            icon:'mdi-view-dashboard',
            routinglink:'/latradts_canditate/dashboard'
        },
        
        {
            name:'Take Exam',
            icon:'mdi-note-edit',
            routinglink:'/latradts_canditate/exam'
        },
        {
            name:'Booking',
            icon:'mdi-book',
            routinglink:'/latradts_canditate/booking'
        },
    ],
        }
    }

}
</script>

<style>

</style>