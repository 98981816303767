<template>
  <v-app>
    <v-main>
      <p>{{user.name}}</p>
      <router-view />
    </v-main>
  </v-app>
</template>
<style>
#app{
  /* background: url('~@/assets/flag_login.png') no-repeat center center fixed !important; */
  background-size: cover;
}
</style>
<script>
import {mapGetters} from 'vuex'

export default {
  name: "App",
  components:{
  },
  data: () => ({
    //
    token:null
  }),
  methods: {
    logOut: function () {
      this.$store.dispatch('logOut')
        .then(() => this.$router.push('/login'))
    }

  },
  computed: {
    ...mapGetters(['authStatus', 'user'])
  }
};
</script>
