<template>
    <v-card outlined style="position:fixed;left:10px">
    <v-card
        class="pa-5"
        outlined
        left
    >
      <span class="d-flex">
      <v-icon color="info" large left>mdi-trophy</v-icon>
          <h2>{{ StatisticsValues.totalAnsweredQuestions }}/{{ StatisticsValues.totalQuestions }}</h2>
      </span>
      <p>Maswali Yaliyojibiwa</p>
    </v-card>
    <span v-if="StatisticsValues.totalAnsweredQuestions!==StatisticsValues.totalQuestions ">
      <v-card
          class="pa-5"
          outlined
      >
      <span class="d-flex">
      <v-icon color="#EB8822" large left>mdi-timer</v-icon>
      <h2>{{ StatisticsValues.averageTimePerQuestion }}</h2>
      </span>
      <p>Wastani wa muda kwa kila swali</p>
      </v-card>
      <v-card
          class="pa-5"
          outlined
      >
        <span v-if="StatisticsValues.progress==='Nzuri'" class="d-flex"><v-icon color="success" large left>mdi-checkbox-marked-circle</v-icon><h1 color="info">{{ StatisticsValues.progress }}</h1></span>
        <span v-else class="d-flex"><v-icon color="error" large left>mdi-close-circle</v-icon><h2 color="info">{{ StatisticsValues.progress }}</h2></span>
        <p>Hali ya mtihani</p>
      </v-card>
    </span>
      <v-card v-else
          class="pa-5"
          outlined
      >
        <span class="d-flex"><v-icon color="success" large left>mdi-checkbox-marked-circle</v-icon><h1 color="info">Umemaliza</h1></span>
        <p>Unaweza ukapitia kisha ukawasilisha Mtihani.</p>
      </v-card>
    <v-card class="pa-5">
      <v-spacer></v-spacer> <v-btn class="mt-5 mb-5 white--text" color="warning" large @click="$emit('requestPermission')">Omba Ruhusa</v-btn><v-spacer></v-spacer>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "CandidateExaminationStatistics",
  props:["ExaminationLoaded","StatisticsValues"],
  data(){
    return{
      totalQuestion:0
    }
  },
}
</script>

<style scoped>

</style>