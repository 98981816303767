<template>
  <v-row justify="center">
    <v-dialog
      v-model="EditVenueDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Edit Examination Venue</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="LocationName"
                        :rules="nameRules"
                        label="Name *"
                        required
                        prepend-icon="mdi-domain"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="SeatCapacity"
                        label="Seats Capacity *"
                        required
                        type="number"
                        prepend-icon="mdi-seat-outline"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="AvailableCapacity"
                        label="Available Capacity *"
                        required
                        type="number"
                        prepend-icon="mdi-seat-outline"
                    >
                    </v-text-field>
                      <v-select
                        v-model="Region"
                        :items="$store.state.RegionsStore.allRegions"
                        prepend-icon="mdi-map-marker"

                        item-text="regionName"
                        item-value="primaryKey"
                        chips
                        label="Select Region"
                        
                      ></v-select>
<!--                          <v-switch-->
<!--                              v-model="venuesIsActive"-->
<!--                              :label="venuesIsActive? 'Active' :'Inactive'"-->
<!--                              color="success"-->
<!--                              hide-details-->
<!--                              @change="consoleMethod()"-->
<!--                          ></v-switch>-->
                        </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="#EB8822"
            fab
            small
            class="white--text"
           @click="EditVenue"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

export default {
    components:{

    },
    data: () => ({
        checkbox:false,
        openLocationDialog:false,
        time: null,
        menu4:false,
        menu3: false,
        modal2: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,
        LocationName:'',
        SeatCapacity:'',
        AvailableCapacity:'',
        venuesUniqueId:'',
        venuesIsActive:false,
        regions:[],
        nameRules: [
            v => !!v || 'Location name is required',
            v => v.length <= 30 || 'Location name must be less than 10 characters',
        ],
        Region: '',
        District: [],
    }),
    props: ["EditVenueDialog","EditVenueItems"],
    methods:{
      EditVenue: function(){
        if(parseInt(this.SeatCapacity)>= parseInt(this.AvailableCapacity)){
          let data={
            venuesUniqueId:this.venuesUniqueId,
            venuesName:this.LocationName,
            venuesRegion:this.Region,
            venuesCapacity:this.SeatCapacity,
            venuesAvailableSeats:this.AvailableCapacity,
            venuesIsActive:this.venuesIsActive,
          }
          this.$store.dispatch('EditVenue',data).then(()=>{
            this.$emit('close')
          })
        }else{
          this.$swal({
            toast:true,
            position:'top-right',
            title:'Available capacity Exceeds Seat Capacity!',
            text:'Please Revise',
            timerProgressBar:true,
            timer:10000,
            icon:'error',
            showConfirmButton:false,
            animation:false,
          })
        }
      },
      consoleMethod(){
        console.log(this.venuesIsActive)
      }
    },
    watch:{
      EditVenueDialog:function(){
        this.LocationName=this.EditVenueItems.venueName
        this.SeatCapacity=this.EditVenueItems.capacity
        this.AvailableCapacity=this.EditVenueItems.available
        this.Region=this.EditVenueItems.region.primaryKey
        this.venuesUniqueId=this.EditVenueItems.venuesUniqueId
        this.venuesIsActive=this.EditVenueItems.isActive
      }
    }
  }
</script>