<template>
  <v-container fluid>
    <CreateExaminationSection :CreateExaminationSectionDialog="CreateExaminationSectionDialog" @close="CreateExaminationSectionDialog=false"/>
    <EditExaminationSection :EditExaminationSectionDialog="EditExaminationSectionDialog" :ExaminationSectionItems="examinationSectionItems" @close="EditExaminationSectionDialog=false"/>
        <v-row>
          <v-btn class="rounded-lg ml-5 text-capitalize"  color="#EB8822 " dark right style="margin:5px" @click="CreateExaminationSectionDialog =true">
              <v-icon class="white--text" small>mdi-plus-circle</v-icon>  Create Examination Section
          </v-btn>

        </v-row>
    <v-row>
      <v-col sm="12">
        <div style="padding:10px">
          <template>
            <v-card>
              <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    hide-details
                    label="Search"
                    single-line
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="$store.state.ExaminationSectionStore.allExaminationSections"
                  :search="search"
              >
                <template v-slot:item.sectionUniqueId="{ item }">
                  <v-btn fab small text @click="openDialog(item)">
                    <v-icon color="orange" small>mdi-pen</v-icon>
                  </v-btn>
                  <v-btn fab small text @click="deleteExaminationSection(item)">
                    <v-icon color="error" small>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditExaminationSection from './EditExaminationSection.vue'
import CreateExaminationSection from './CreateExaminationSection.vue'

export default {
  data:()=>({
    CreateExaminationSectionDialog:false,
    EditExaminationSectionDialog:false,
    examDuration:'',
    examNumberOfQuestions:'',
    examNumberOfSections:'',
    examNumberOfOptionPerQuestion:'',
    examExaminationFlow:'',
    examsettingName:'',
    examsettingUniqueId:'',
    examinationSectionItems:null,
    search: '',
    headers: [
      {
        text: 'Section Name',
        value: 'sectionName',
      },
      { text: 'Section Description', value: 'sectionDescriptions' },
      { text: 'edit', value: 'sectionUniqueId' },


    ],
    examinations: []
  }),
  components:{
    EditExaminationSection,
    CreateExaminationSection,
  },
  mounted(){
    this.$store.dispatch('LoadExaminationSections')

  },methods:{
    openDialog:function(value){
      console.log(value)
      this.examinationSectionItems=value
      this.EditExaminationSectionDialog=true
    },
    deleteExaminationSection(value){
      this.examinationSectionItems=value
      let data={
        sectionUniqueId:value.sectionUniqueId
      }
      this.$store.dispatch("deleteExaminationSection",data)
    }
  }
}
</script>

<style>

</style>