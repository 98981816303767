import {apolloClient} from '@/vue-apollo'
import allCancellationLimits from "../graphql/allCancelationLimits.gql";
import updateCancellationLimit from "../graphql/updateCancelationLimit.gql";
import Vue from "vue";

export default {
    state: {
        cancellationLimits:[]
    },
    mutations: {
        AddCancellationLimits(state,data){
            if(state.cancellationLimits.find(({limit_unique_number})=>limit_unique_number === data.limit_unique_number)){
                state.cancellationLimits.find(({limit_unique_number})=>limit_unique_number === data.limit_unique_number).limit_number=data.limit_number
            }else{
                state.cancellationLimits.push(data)
            }
        },
        clearCancellationLimits(state){
            state.cancellationLimits=[]
        }
    },
    actions: {
        async LoadCancellationLimits(context){
            context.commit('clearCancellationLimits')

            apolloClient.query({
                query:allCancellationLimits,
            }).then((response)=>{
                console.log(response.data.allCancelationLimits.edges)
                response.data.allCancelationLimits.edges.forEach(element=>{
                    let data={
                        limit_unique_number:element.node.limitUniqueId,
                        limit_number:element.node.limitNumber,
                    }
                    context.commit('AddCancellationLimits',data)
                })

            })

        },
        async addCancellationLimit(context,data){
            apolloClient.mutate({
                mutation:updateCancellationLimit,
                variables:data
            }).then((response)=>{
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Cancellation Limit Created Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }else{
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Oops!, Looks like an error occurred',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }
                let data={
                    limit_unique_number:response.data.updateCancelationLimit.cancelLimit.limitUniqueId,
                    limit_number:response.data.updateCancelationLimit.cancelLimit.limitNumber,
                }
                context.commit('AddCancellationLimits',data)
            })
        }
    }
}