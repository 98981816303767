<template>
<v-container>
  <LandingToolBar :Role="role"/>
  <v-row v-if="!$store.getters.roleIsActive" justify="center">
    <v-col
        lg="6"
        md="6"
        sm="12"
    >
      <v-alert
          border="top"
          color="error accent-4"
          colored-border
          elevation="2"
          icon="mdi-alert-circle"
          prominent
      >
        <div class="text-h6">
          Samahani!, Ruhusa(Role) kufikia ukurasa huu Imezimwa.
        </div>
        Tafadhali wasiliana na Msimamizi wa mfumo, ili kutatua tatizo hili.
      </v-alert>
    </v-col>
  </v-row>

  <v-row v-else>
    <v-row v-for="cards in dashboardCards" :key="cards"  align="center" dense justify="center" style="padding:10px;">
      <div v-for="card in cards"
           :key="card.title">
        <v-col v-if="card.permission" :cols="card.flex" style="max-width:300px">
          <v-card  :to="card.link" class="rounded-lg ma-1" link outlined>
            <v-img
                :src="card.src"
                class="white--text align-end rounded-lg"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200px"
                max-width="300px"
            >
              <v-card-title v-text="card.title"></v-card-title>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn block class="font-weight-medium rounded-lg text-capitalize" color="#EB8822" dark @click="navigateToPage(card.link)">
                <v-icon small>{{card.icon}}</v-icon> {{card.text_content}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
    </v-row>
  </v-row>

</v-container>
</template>

<script>
import LandingToolBar from './LandingToolBar.vue'
import Getters from '../../store/store'

export default {
        components:{
            LandingToolBar
        },
        data () {
            return {
              permissions:[],
              role:'',
                items: [
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                }
                ],
                userdata:Getters.state.user,
                dashboardCards:[
                [
                    { title: 'Fanya Mtihani', src: '/takeExam.png', flex: 4, md: 6, link:'latradts_canditate/exam',icon:'mdi-pen', text_content:'Fanya Mtihani',permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_take_exam')!==undefined},
                    { title: 'Booking', src: '/booking.jpg', flex: 4, md: 6, link:'/latradts_canditate/booking',icon:'mdi-book-variant', text_content:'View Booking',permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_book_exam')!==undefined},
                    { title: 'Invigilation', src: '/invigilation.jpg', flex: 4, md: 6, link:'latradts_invigilator/exam',icon:'mdi-account-eye', text_content:'Invigilate', permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_invigilate_exam')!==undefined},
                    { title: 'Questions Bank', src: '/questionbanks.png', flex: 4, md: 6, link:'/latradts/question_bank',icon:'mdi-archive', text_content:'Questions Bank', permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_question_bank')!==undefined},
                    { title: 'Reports', src: '/Dashboards.png', flex: 4, md: 6, link:'latradts_canditate/resultreport',icon:'mdi-file-chart-outline', text_content:'Reports', permission:true},
                    { title: 'Users Management', src: '/Clients.png', flex: 4, md: 6, link:'/latradts_settings/users',icon:'mdi-account-group', text_content:'Users Management', permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_user')!==undefined},
                
                    { title: 'Examinations Setup', src: '/Setup.png', flex: 4, md: 6, link:'/latradts_settings/examination_settings',icon:'mdi-archive-cog', text_content:'Examinations Setup', permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_examination_settings')!==undefined},
                    { title: 'System Settings', src: '/Settings.jpg', flex: 4, md: 6, link:'/latradts_settings/resultreport',icon:'mdi-database-cog', text_content:'System Settings', permission:this.$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_view_system_settings')!==undefined},
                    // { title: 'Self Service', src: '/Service.png', flex: 4, md: 6, link:'/latradts/questionReport',icon:'mdi-account-cog', text_content:'Self Service', permission:true},
                ],


                ]

            }
        },
        methods:{
            navigateToPage(link){
                this.$router.push({
                    path: link, Logout
                });
            }
        },
  mounted() {
            this.$store.state.AuthStore.user.userroleUser.edges[0].node.userroleRole.templateRole.edges.forEach(permissions=>{
            this.permissions.push(permissions.node.templatePermission)
          })
    this.role=this.$store.state.AuthStore.user.userroleUser.edges[0].node.userroleRole.roleDescriptions

  }

}
</script>

<style>

</style>