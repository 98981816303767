import SystemMain from './SystemMain.vue'
import UsersList from './system_settings/users/UsersList.vue'
import VenuesManagements from './system_settings/venues/VenuesManagements.vue'
import ExaminationSettings from './system_settings/examination_settings/ExaminationSettings.vue'
import ExaminationSections from './system_settings/ExaminationSections/ExaminationSectionMain.vue'
import QuestionSettings from './system_settings/question/QuestionSettings.vue'
import TimetableSettings from './system_settings/timetable/TimetableSettings.vue'
import ExaminationManagement from './system_settings/examination/ExaminationManagement.vue'
import BookingManagement from './system_settings/booking/BookingManagement.vue'
import ExaminationBookingList from './system_settings/booking/ExaminationBookingList.vue'
import TestCenterConfiguration from './system_settings/fingerprint_device_configuration/FingerprintDeviceConfiguration'
import sessionReport from '../latradts_report/sessionReport.vue'
import testCenterReport from '../latradts_report/testCenterReport.vue'
import candidateReport from '../latradts_report/candidateReport.vue'
import resultreport from '../latradts_report/resultreport.vue'

export default [
    {
        path: '/latradts_settings',
        name:'CandidateMain',
        component: SystemMain,
        meta: { requiresAuth: true },
        children:[
            {
                path: 'users',
                name:'Users',
                component: UsersList,
                meta:{
                    permissions:['can_view_user']
                }
            },
            {
                path: 'venues',
                name:'ExaminationVenues',
                component: VenuesManagements,
                meta: {
                    permissions:['can_view_user']

                }
            },
            {
                path: 'examination_settings',
                name:'Examination',
                component: ExaminationSettings,
                meta:{
                    permissions: ['can_view_examination_settings']
                }
            },
            {
                path: 'examination_sections',
                name:'ExaminationSections',
                component: ExaminationSections,
                meta:{
                    permissions: ['can_view_examination_sections']
                }
            },
            {
                path: 'examination',
                name:'Examination',
                component: ExaminationManagement,
                meta:{
                    permissions: ['can_view_examination_categories']
                }
            },
            {
                path: 'questions',
                name:'Question',
                component: QuestionSettings,
                meta: {
                    permissions: ['can_view_question_bank']
                }
            },
            {
                path: 'timetable',
                name:'TimetableSettings',
                component: TimetableSettings,
                meta:{
                    permissions: ['can_view_timetable_sessions']
                }
            },
            {
                path: 'timetable/:venueName',
                name:'TimetableSettings',
                component: TimetableSettings,
                meta: {
                    permissions: ['can_view_timetable_sessions']
                }
            },
            {
                path: 'booking',
                name:'BookingManagement',
                component: BookingManagement,
                meta: {
                    permissions: ['can_view_booking_settings']
                }
            },
            {
                path: 'examination_booking_list',
                name:'ExaminationBookingList',
                component: ExaminationBookingList,
            },
            {
                path: 'fingerprint_device_configuration',
                name:'TestCenterConfiguration',
                component: TestCenterConfiguration,
                meta:{
                    permissions: ['can_view_fingerprint_device']
                }
            },

            {
                path: 'sessionReport',
                name:'sessionReport',
                component: sessionReport,
                meta: {
                    permissions: ['can_view_session_report']
                }
            },

            {
                path: 'testCenterReport',
                name:'testCenterReport',
                component: testCenterReport,
                meta: {
                    permissions: ['can_view_test_center_report']
                }
            },
            {
                path: 'candidateReport',
                name:'candidateReport',
                component: candidateReport,
                meta: {
                    permissions: ['can_view_candidate_report']
                }
            },
            {
                path: 'resultreport',
                name:'resultreport',
                component: resultreport,
                meta:{
                    permissions: ['can_view_test_center_report']
                }
            },
        ]
     },    
]