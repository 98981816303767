<template>
<v-banner outlined shaped type="">
      <v-chart class="chart" :option="option" />
</v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: "HelloWorld",
  props:['dataLabels','dataValues'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "white"
  },
data() {
    return {
      option:{
       legend: {
         orient: "horizontal",
         left: "left",
       },
       title: {
          text: "Total Question Vs Question Category ",
          top: "top",
        },  
     tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
          }
      },
// 'question_category__category_name'
      xAxis: {
        axisLabel: { interval: 0, rotate: 10 },
        type: 'category',
        data: this.dataLabels
      },
      yAxis: {
        type: 'value',
      },
       series: [
        {
          data: this.dataValues,
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    },
    }
  }
};

</script>

<style scoped>
.chart {
  height: 400px;
}
</style>




