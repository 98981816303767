<template>
  <v-row justify="center">
    <v-dialog
      v-model="EditExaminationSettingDialog"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-pen</v-icon> Edit Examination Setting</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="settingName"
                        :rules="nameRules"
                        :counter="30"
                        label="Setting Name *"
                        required
                        prepend-icon="mdi-cog"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="Duration"
                        :rules="nameRules"
                        :counter="30"
                        label="Duration in hours *"
                        required
                        prepend-icon="mdi-clock"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="NumberOfOptionPerQuestion"
                        label="Number of options per question *"
                        required
                        type="number"
                        prepend-icon="mdi-multicast"
                    >
                    </v-text-field> 
                      <v-select
                        v-model="ExaminationFlow"
                        :items="flow"
                        prepend-icon="mdi-chart-timeline-variant-shimmer"
                        attach
                        item-text="name"
                        item-value="code"
                        chips
                        label="Select Examination Flow"
                      ></v-select>
                      <v-combobox
                          v-model="ExaminationSections"
                          :items="$store.state.ExaminationSectionStore.allExaminationSections"
                          chips
                          item-text="sectionName"
                          label="Examination Sections *"

                          multiple
                          prepend-icon="mdi-select"
                          required
                          @change="categoryShow"
                      >
                      </v-combobox>
                    </v-col>
                      <v-col
                          v-for="section in SelectedExaminationSections"
                          :key="section.unique_id"
                          cols="12"
                          md="6"
                          sm="12"
                      >
                        <div
                            class="text-h5"
                        >
                          Section {{section.sectionName}}</div>
                        <v-combobox
                            :items="$store.state.CategoriesStore.categorylist"
                            chips
                            item-text="name"
                            label="Category"
                            multiple
                            :value="section.categories"
                            @change="addQuestionNoField(section,$event)"
                        >

                        </v-combobox>
                        <v-row>
                          <v-col
                              v-for="item in ExaminationSectionsContent.find(({primaryKey}) => primaryKey === section.key).category"
                              :key="item.actions"
                              cols="12"
                              md="6"
                              sm="6"
                          >
                            <v-text-field
                                :label="item.name"
                                cols="12"
                                dense
                                md="6"
                                sm="6"
                                type="number"
                                :value="item.questionNo"
                                @change="setQuestionNumber(section.key,item,$event)"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>


                      </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="#EB8822"
            fab
            small
            class="white--text"
           @click="EditExaminationSetting"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>


  export default {
    components:{
        
    },
    data() {
  return {
        checkbox:false,
        openLocationDialog:false,
        time: null,
        menu4:false,
        menu3: false,
        modal2: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,   
        Duration:null,
        NumberOfQuestions:null,
        ExaminationSections:[],
        NumberOfOptionPerQuestion:null,
        SelectedExaminationSections:[],
        ExaminationSectionsContent:[],
        ExaminationFlow:null,
        settingName:null,
        settingUniqueId:null,
        regions:[], 
        nameRules: [
            v => !!v || 'Location name is required',
            v => v.length <= 30 || 'Location name must be less than 10 characters',
        ],
        District: [],
        flow:[
            {'code':'SS', 'name':'Single Section'},
            {'code':'SQ', 'name':'Single Question'},
            {'code':'AQ', 'name':'All Questions'},
        ]
    }},
    props: ["EditExaminationSettingDialog","ExaminationSettingItems"],
    mounted(){
      this.$store.dispatch('loadCategoriesFromServer')
      this.$store.dispatch('LoadExaminations')
    },
    methods:{
      EditExaminationSetting: function(){
        let data={
          settingUniqueId:this.settingUniqueId,
          settingDuration:this.Duration,
          settingExaminationFlow:this.ExaminationFlow,
          settingName:this.settingName,
          settingNumberOfOptionsPerQuestion:this.NumberOfOptionPerQuestion,
          sectionList:JSON.stringify(this.ExaminationSectionsContent),
        }
        this.$store.dispatch('updateExaminationSetting',data).then(()=>{
          this.$emit('close')
        })
      },
      setQuestionNumber(unique,category,questionNumber){

        console.log(questionNumber)
        console.log(unique)
        console.log(category.key)
        console.log("Hivi hapa")
        this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === unique).category.find(({key}) => key === category.key).questionNo=questionNumber
        console.log(this.ExaminationSectionsContent)
        console.log(this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === unique))
        console.log(this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === unique).category)
        console.log(this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === unique).category.find(({key}) => key === category.key).questionNo)
      },
      categoryShow:function(){
        this.SelectedExaminationSections=[]
        // this.ExaminationSectionsContent=[]
        this.ExaminationSections.forEach(element=>{
          this.SelectedExaminationSections.push(element)
          this.ExaminationSectionsContent.push({
            primaryKey:element.key,
            category:[]
          })
        })
        console.log(this.ExaminationSections)

      },
      addQuestionNoField(section,value){
        this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === section.key).category=[]
        value.forEach(element=>{
          this.ExaminationSectionsContent.find(({primaryKey}) => primaryKey === section.key).category.push(element)
          console.log(section,value)
        })
      },
      receiveUpdatedQuestionsAndCategories(data){
        console.log(data);
      }
    },computed:{

    },watch: {
    // whenever question changes, this function will run
    EditExaminationSettingDialog: function () {
        this.Duration=this.ExaminationSettingItems.duration
        this.ExaminationSections=[]
        this.ExaminationSectionsContent=[]
      console.log(this.ExaminationSettingItems.sections)
        this.ExaminationSettingItems.sections.forEach(element=>{

          let categories=[];
          element.categories.forEach(category=>{
            categories.push({
              CatSectionUniqueId:category.CatSectionUniqueId,
              actions:"",
              active_status:true,
              created_date:"",
              key:category.primaryKey,
              name:category.name,
              questionNo:category.noq,
              relayId:""
            })
          })
          let section={
            sectionName: element.sectionName,
            key:element.primaryKey,
            unique_id:element.unique_id,
            categories:categories
          }
          this.ExaminationSections.push(section)
          this.categoryShow();
          console.log(element)
          this.addQuestionNoField(section,categories)
          // this.ExaminationSectionsContent.push({
          //   primaryKey:element.primaryKey,
          //   category:[]
          // })
        })
        // this.ExaminationSections=this.ExaminationSettingItems.sections,
        this.NumberOfOptionPerQuestion=this.ExaminationSettingItems.opq
        this.ExaminationFlow=this.ExaminationSettingItems.flow
        this.settingName=this.ExaminationSettingItems.name
        this.settingUniqueId=this.ExaminationSettingItems.edit
    }
  } 
    
  }
</script>