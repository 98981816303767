<template>
    <v-container fluid >
       
      <v-row>
        <v-col class="d-flex justify-end" cols="12" sm="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    dark
                    fab
                    small
                     color="#EB8822"
                    v-bind="attrs"
                    v-on="on"
                    @click="backToCategory"
                    class="mx-2"
                    >
                     <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                </template>
                <span>Back to Dashboard</span>
                </v-tooltip>
          <v-btn class="rounded-xl" color="primary" download href="https://dts.latra.go.tz:4443/latradts_media/questions_bank.xlsx"><v-icon>mdi-download</v-icon>Download Template</v-btn>
<!--          <v-btn class="rounded-xl" color="primary" download href="https://latradts.mikutano.co.tz:8090/latradts_media/questions_bank.xlsx"><v-icon>mdi-download</v-icon>Download Template</v-btn>-->
          <v-btn class="mx-2 rounded-xl font-weight-medium" color="#EB8822" dark   @click.stop="createQuestionCategory = true">
            <v-icon>mdi-plus</v-icon>
            Add Question Category
          </v-btn>
        </v-col>
      </v-row>
      <CreateCategory :CreateQuestionCategory="createQuestionCategory" class="mb-5 ml-0" @close="createQuestionCategory=false"/>


        <v-card>
            <v-card-title>
                <v-toolbar-title class="text-h6">Question Category</v-toolbar-title>
                <v-divider class="mx-4" color="orange " inset vertical ></v-divider>
                <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            
            <v-data-table
            :headers="headers"
            :items="questionCategores"
            :search="search"
            >
            <template v-slot:item.actions="{ item }">
                <v-row>
                     <v-btn dark fab small color="orange darken-2" class="mx-2" @click="navigateToQuestions(item.actions,item.name,item.key,item.relayId)">
                    <v-icon small>mdi-eye</v-icon>
                </v-btn>
                
                    <v-btn dark fab small color="primary" class="mx-2" @click="showEditCategoryForm=true;  categoryName=item.name;categoryIsActive=item.active_status;categoryUniqueId=item.actions">
                    <v-icon small>mdi-pen</v-icon>
                </v-btn>

                 <v-btn class="mx-2" color="primary" dark fab small @click="showAddQuestionForm=true; createDialog=true; categoryName=item.name;categoryUniqueId=item.key">
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>

                </v-row>
                
            </template>
            <template v-slot:item.active_status="{ item }">
                <v-btn dark 
                v-if="item.active_status"
                        color="success"
                        depressed
                        rounded
                        x-small
                        class="text-capitalize">
                    {{ item.active_status }}
                </v-btn>
                <v-btn dark 
                v-else
                        color="error"
                        depressed
                        rounded
                        x-small
                        class="text-capitalize">
                    {{ item.active_status }}
                </v-btn>
            </template>
            </v-data-table>
        </v-card>
        <EditCategory :editCategoryVisible="showEditCategoryForm" :categoryIsActive="categoryIsActive" @closeEdit="showEditCategoryForm=false" :categoryUniqueId="categoryUniqueId" :categoryName="categoryName"/>
        <NewQuestionForm :AddQuestionVisible="showAddQuestionForm" :categoryId="categoryUniqueId" :createDialog="createDialog" :questions="question" @close="createDialog=false" />
    </v-container>
</template>

<script>
import CreateCategory from '../../latradts_system/system_settings/question/CreateCategory.vue'
import NewQuestionForm from './NewQuestionForm.vue'
import EditCategory from './EditQuestionCategory.vue'

export default {
    data:()=>({
        question:"Add New Question In This Category",
        search: '',
        categoryIsActive:false,
        createQuestionCategory:false,
        categoryUniqueId:'',
        categoryName:'',
        showEditCategoryForm:false,
        showAddQuestionForm:false,
        createDialog:false,
        headers: [
        { text: 'Category Name', align: 'start', value: 'name',},
        { text: 'Active Status', value: 'active_status' },
        { text: 'Created Date', value: 'created_date' },
        { text: 'Actions', value: 'actions' },
        ],
    }),
    components:{
        CreateCategory,
        EditCategory,
        NewQuestionForm
    },
    mounted(){
        this.$store.dispatch('loadCategoriesFromServer')
    },
    computed: {
        questionCategores(){
            return this.$store.state.CategoriesStore.categorylist
        }
    },
    methods: {
        navigateToQuestions(categoryUniqueId,categoryName,categoryId,relayId){
            var selected={ categoryUniqueId: categoryUniqueId,categoryName:categoryName,categoryId:categoryId,categoryRelayID:relayId }
            this.$store.dispatch('updateSelectedCategory',selected)
            this.$router.push({ name: 'QuestionsList'})
        },
        backToCategory(){
        this.$router.push({ path: '/latradts/questionReport'});
      },
       
        
    }
}
</script>