<template>
<v-container fluid>
     <v-card>
        <v-card-title>  
          <v-btn  color="primary">Test Center Report</v-btn>
          <v-divider class="mx-4" color="orange " inset vertical ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
    <v-data-table
      :headers="headers"
      :items="this.$store.state.ReportStore.centerReport"
      item-key="candidateLastName"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
    </v-data-table>
     </v-card>
</v-container>
</template>
<script>
  export default {
    data () {
      return {
        search: '',
      }
    },
    mounted () {
      this.$store.dispatch('AllTestCenterReport')
    },

    computed: {
      headers () {
        return [
          {
            text: 'Venue',
            align: 'start',
            sortable: false,
            value: 'CenterName',
          },
          { text: 'Region',value: 'Location'},
          { text: 'Venue Capacity', value: 'capacity',
           filter: value => {
              if (!this.candidateTotalBookings) return true

              return value < parseInt(this.candidateTotalBookings)
            }, },
          { text: 'Venue Slot', value: 'slot' },
          { text: 'Examination Appeared', value: 'examAppeared' },
          
        ]
      },
    },
    methods: {
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
    },
  }
</script>