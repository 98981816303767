import { apolloClient } from "@/vue-apollo";
import staffProfile from "../../graphql/StaffProfile/staffProfile.graphql";
import allUsers from "../../graphql/allUsers.gql";
import updateUserActiveStatus from "../../graphql/updateUserActiveStatus.gql";
import Vue from "vue";
import register from "../../graphql/register.gql";
import updateAfterRegister from "../../graphql/updateAfterRegister.gql";

export default {
  state: {
    allUsers: [],
    allStaffs: [],
    allCandidates: [],
    totalPages: 0,
    totalCount:0,
  },
  mutations: {
    LoadStaffs(state, data) {
      state.allStaffs.push(data);
    },
    LoadUsers(state, data) {
      state.allUsers.push(data);
    },
    LoadCandidates(state, data) {
      state.allCandidates.push(data);
    },
    editStaff(state, data) {
      state.allStaffs.find(({ relayId }) => relayId === data.relayId).name =
        data.name;
      state.allStaffs.find(
        ({ relayId }) => relayId === data.relayId
      ).firstName = data.firstName;
      state.allStaffs.find(({ relayId }) => relayId === data.relayId).lastName =
        data.lastName;
      state.allStaffs.find(
        ({ relayId }) => relayId === data.relayId
      ).activeStatus = data.activeStatus;
      state.allStaffs.find(({ relayId }) => relayId === data.relayId).email =
        data.email;
      state.allStaffs.find(
        ({ relayId }) => relayId === data.relayId
      ).phoneNumber = data.phoneNumber;
      state.allStaffs.find(({ relayId }) => relayId === data.relayId).venue =
        data.venue;
      state.allStaffs.find(
        ({ relayId }) => relayId === data.relayId
      ).staffStatus = data.staffStatus;
      state.allStaffs.find(
        ({ relayId }) => relayId === data.relayId
      ).superUser = data.superUser;
      state.allStaffs.find(({ relayId }) => relayId === data.relayId).date =
        data.date;
      state.allStaffs.find(({ relayId }) => relayId === data.relayId).role =
        data.role;
    },
    editCandidate(state, data) {
      state.allCandidates.find(({ relayId }) => relayId === data.relayId).name =
        data.name;
      state.allCandidates.find(
        ({ relayId }) => relayId === data.relayId
      ).firstName = data.firstName;
      state.allCandidates.find(
        ({ relayId }) => relayId === data.relayId
      ).lastName = data.lastName;
      state.allCandidates.find(
        ({ relayId }) => relayId === data.relayId
      ).activeStatus = data.activeStatus;
      state.allCandidates.find(
        ({ relayId }) => relayId === data.relayId
      ).email = data.email;
      state.allCandidates.find(
        ({ relayId }) => relayId === data.relayId
      ).phoneNumber = data.phoneNumber;
      state.allCandidates.find(
        ({ relayId }) => relayId === data.relayId
      ).venue = data.venue;
      state.allCandidates.find(
        ({ relayId }) => relayId === data.relayId
      ).staffStatus = data.staffStatus;
      state.allCandidates.find(
        ({ relayId }) => relayId === data.relayId
      ).superUser = data.superUser;
      state.allCandidates.find(({ relayId }) => relayId === data.relayId).date =
        data.date;
      state.allCandidates.find(({ relayId }) => relayId === data.relayId).role =
        data.role;
    },
    editActiveStatus(state, data) {
      if (state.allCandidates.find(({ relayId }) => relayId === data.relayId)) {
        state.allCandidates.find(
          ({ relayId }) => relayId === data.relayId
        ).activeStatus = data.activeStatus;
      }
      if (state.allStaffs.find(({ relayId }) => relayId === data.relayId)) {
        state.allStaffs.find(
          ({ relayId }) => relayId === data.relayId
        ).activeStatus = data.activeStatus;
      }
    },
    clearUsers(state) {
      state.allUsers = [];
      state.allStaffs = [];
      state.allCandidates = [];
    },
    setTotalPages(state, data) {
      state.totalPages = data??0;
    },
    setTotal(state, data) {
      //The minus one is to remove the rrims user
      state.totalCount = data??0;
    },
  },
  actions: {
    async LoadStaff(context, data) {
      context.commit("clearUsers");
      apolloClient
        .query({
          query: staffProfile,

        })
        .then((response) => {
          let totalPages = Math.floor(
            response.data.staffProfile.totalCount / 10
          );
          context.commit("setTotalPages", totalPages);
          context.commit("setTotal", response.data.staffProfile.totalCount);
          response.data.staffProfile.results.forEach((element) => {
            let fullName = "No--Name--Assigned";
            if (
              element.staffUser.firstName ||
              element.staffUser.lastName
            ) {
              fullName =
                element.staffUser.firstName +
                " " +
                element.staffUser.lastName;
            }
            let role = "Un-Assigned";
            if (element.staffUser.userroleUser.edges[0]) {
              role =
                element.staffUser.userroleUser.edges[0].node.userroleRole;
            }
            let dataInside = {
              relayId: element.staffUser.id,
              name: fullName,
              firstName: element.staffUser.firstName,
              lastName: element.staffUser.lastName,
              activeStatus: element.staffUser.isActive,
              email: element.staffUser.email,
              phoneNumber: element.staffPhone,
              venue: element.staffVenue,
              staffStatus: element.staffUser.isStaff,
              superUser: element.staffUser.isSuperuser,
              date: new Date(element.staffUser.dateJoined)
                .toString()
                .substr(0, 15),
              role: role,
            };
            if (data) {
              if (role !== "Un-Assigned")
                context.commit("LoadStaffs", dataInside);
            }
          });
        });
    },
    async LoadCandidate(context, data) {
      console.log(data.offset);
      context.commit("clearUsers");
      apolloClient
        .query({
          query: allUsers,
          variables: {
            isStaff: false,
            limit: 10,
            offset: data?.offset?(10*(data?.offset-1)):0
          },
        })
        .then((response) => {
          let totalPages = Math.floor(response.data.allUsers.totalCount / 10
          );
          context.commit("setTotalPages",totalPages);
          context.commit("setTotal",response.data.allUsers.totalCount-1);
          response.data.allUsers.results.forEach((element) => {
            let fullName = "No--Name--Assigned";
            if (element.firstName || element.lastName) {
              fullName = element.firstName + " " + element.lastName;
            }
            let role = "Un-Assigned";
            if (element.userroleUser.edges[0]) {
              role = element.userroleUser.edges[0].node.userroleRole;
            }
            let dataInside = {
              relayId: element.id,
              name: fullName,
              firstName: element.firstName,
              lastName: element.lastName,
              activeStatus: element.isActive,
              email: element.email,
              phoneNumber: element.staffPhone,
              venue: element.staffVenue,
              staffStatus: element.isStaff,
              superUser: element.isSuperuser,
              date: new Date(element.dateJoined).toString().substr(0, 15),
              role: role,
            };
            if (data.isStaff) {
              if (role !== "Un-Assigned")
                context.commit("LoadStaffs", dataInside);
            } else {
              if (role !== "Un-Assigned")
                context.commit("LoadCandidates", dataInside);
            }
          });
        });
    },
    async EditUserActiveStatusAdmin(context, data) {
      apolloClient
        .mutate({
          mutation: updateUserActiveStatus,
          variables: data,
        })
        .then((response) => {
          if (response.data.updateUserActiveStatus) {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "User has been updated Successfully!",
              timerProgressBar: true,
              timer: 10000,
              icon: "success",
              showConfirmButton: false,
              animation: false,
            });
          } else {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "Opps! Looks like an error Occured",
              text: response.errors[0].message,
              timerProgressBar: true,
              timer: 10000,
              icon: "error",
              showConfirmButton: false,
              animation: false,
            });
          }
          response.data.updateUserActiveStatus.user;
          let dataInside = {
            relayId: response.data.updateUserActiveStatus.user.id,
            activeStatus: response.data.updateUserActiveStatus.user.isActive,
          };
          context.commit("editActiveStatus", dataInside);
        });
    },
    async AddUserAdmin(context, data) {
      apolloClient
        .mutate({
          mutation: register,
          variables: data.first,
        })
        .then((response) => {
          if (response.data.register.success) {
            apolloClient
              .mutate({
                mutation: updateAfterRegister,
                variables: data.second,
              })
              .then((response2) => {
                let fullName = "No--Name--Assigned";
                if (
                  response2.data.updateAfterRegister.user.firstName ||
                  response2.data.updateAfterRegister.user.lastName
                ) {
                  fullName =
                    response2.data.updateAfterRegister.user.firstName +
                    " " +
                    response2.data.updateAfterRegister.user.lastName;
                }
                let role = "Un-Assigned";
                if (
                  response2.data.updateAfterRegister.user.userroleUser.edges[0]
                ) {
                  role =
                    response2.data.updateAfterRegister.user.userroleUser
                      .edges[0].node.userroleRole;
                }
                let dataInside = {
                  relayId: response2.data.updateAfterRegister.user.id,
                  primaryKey:
                    response2.data.updateAfterRegister.user.primaryKey,
                  name: fullName,
                  firstName: response2.data.updateAfterRegister.user.firstName,
                  lastName: response2.data.updateAfterRegister.user.lastName,
                  activeStatus:
                    response2.data.updateAfterRegister.user.isActive,
                  venue: data.second.venue,
                  phoneNumber: data.second.staffPhone,
                  email: response2.data.updateAfterRegister.user.email,
                  staffStatus: response2.data.updateAfterRegister.user.isStaff,
                  superUser:
                    response2.data.updateAfterRegister.user.isSuperuser,
                  date: new Date(
                    response2.data.updateAfterRegister.user.dateJoined
                  )
                    .toString()
                    .substr(0, 15),
                  role: role,
                };
                context.commit("LoadStaffs", dataInside);

                if (response2.data.updateAfterRegister) {
                  Vue.swal({
                    toast: true,
                    position: "top-right",
                    title: "User created Successfully!",
                    timerProgressBar: true,
                    timer: 10000,
                    icon: "success",
                    showConfirmButton: false,
                    animation: false,
                  });
                } else {
                  Vue.swal({
                    toast: true,
                    position: "top-right",
                    title: "Opps! Looks like an error Occured",
                    text: response2.errors[0].message,
                    timerProgressBar: true,
                    timer: 10000,
                    icon: "error",
                    showConfirmButton: false,
                    animation: false,
                  });
                }
              });
          }
        });
    },
    async EditUserAdmin(context, data) {
      apolloClient
        .mutate({
          mutation: updateAfterRegister,
          variables: data,
        })
        .then((response2) => {
          let fullName = "No--Name--Assigned";
          if (
            response2.data.updateAfterRegister.user.firstName ||
            response2.data.updateAfterRegister.user.lastName
          ) {
            fullName =
              response2.data.updateAfterRegister.user.firstName +
              " " +
              response2.data.updateAfterRegister.user.lastName;
          }
          let role = "Un-Assigned";
          if (response2.data.updateAfterRegister.user.userroleUser.edges[0]) {
            role =
              response2.data.updateAfterRegister.user.userroleUser.edges[0].node
                .userroleRole;
          }
          let dataInside = {
            relayId: response2.data.updateAfterRegister.user.id,
            primaryKey: response2.data.updateAfterRegister.user.primaryKey,
            name: fullName,
            firstName: response2.data.updateAfterRegister.user.firstName,
            lastName: response2.data.updateAfterRegister.user.lastName,
            activeStatus: response2.data.updateAfterRegister.user.isActive,
            email: response2.data.updateAfterRegister.user.email,
            venue: {
              primaryKey: data.venue,
            },
            phoneNumber: data.staffPhone,
            staffStatus: response2.data.updateAfterRegister.user.isStaff,
            superUser: response2.data.updateAfterRegister.user.isSuperuser,
            date: new Date(response2.data.updateAfterRegister.user.dateJoined)
              .toString()
              .substr(0, 15),
            role: role,
          };
          if (response2.data.updateAfterRegister.user.isStaff) {
            context.commit("editStaff", dataInside);
          } else {
            context.commit("editCandidate", dataInside);
          }
          if (response2.data.updateAfterRegister) {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "User updated Successfully!",
              timerProgressBar: true,
              timer: 4000,
              icon: "success",
              showConfirmButton: false,
              animation: false,
            });
          } else {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "Opps! Looks like an error Occured",
              text: response2.errors[0].message,
              timerProgressBar: true,
              timer: 10000,
              icon: "error",
              showConfirmButton: false,
              animation: false,
            });
          }
        });
    },
  },
};
