var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('EditUser',{attrs:{"EditUserDialog":_vm.editUserDialog,"EditUserItems":_vm.editUserItems},on:{"close":function($event){_vm.editUserDialog = false}}}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":"","max-width":"100%"}},[_c('v-card-text',[[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.UserStore.allCandidates,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.search,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-regular",staticStyle:{"font-size":"large"}},[_vm._v(" "+_vm._s(item.name)+" "),(item.superUser)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-check-decagram")]):_vm._e()],1),_c('br'),_c('span',{staticClass:"text-caption",staticStyle:{"color":"#777"}},[_vm._v(_vm._s(item.email))])]}},{key:"item.activeStatus",fn:function(ref){
var item = ref.item;
return [(item.activeStatus)?_c('v-btn',{attrs:{"color":"#C8E6C9","depressed":"","rounded":"","x-small":""}},[_c('v-icon',{attrs:{"color":"success","x-small":""}},[_vm._v("mdi-check-circle")]),_c('span',{staticClass:"text-capitalize green--text text-capitalize"},[_vm._v("Active")])],1):_c('v-btn',{attrs:{"color":"#FFCDD2","depressed":"","rounded":"","x-small":""}},[_c('v-icon',{attrs:{"color":"error","x-small":""}},[_vm._v("mdi-close-circle")]),_c('span',{staticClass:"text-capitalize red--text text-capitalize"},[_vm._v("Deactivated")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"app":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","color":"indigo","icon":""},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openEditUserDialog(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-account-edit")]),_vm._v(" Edit User")],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-account-tie")]),_vm._v(" User Profile")],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-sync")]),_vm._v(" Reset Password")],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""}},[(!item.activeStatus)?_c('v-list-item-title',{on:{"click":function($event){return _vm.updateUserActiveStatus(
                            item.email,
                            !item.activeStatus
                          )}}},[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px","color":"green"}},[_vm._v("mdi-check-circle")]),_vm._v(" Activate")],1):(item.activeStatus)?_c('v-list-item-title',{on:{"click":function($event){return _vm.updateUserActiveStatus(
                            item.email,
                            !item.activeStatus
                          )}}},[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px","color":"red"}},[_vm._v("mdi-close-circle")]),_vm._v(" Deactivate")],1):_vm._e()],1)],1)],1)]}}])}),_c('div',{staticClass:"text-center pt-2"},[_vm._v(" Total: "+_vm._s(_vm.$store.state.UserStore.totalCount)+" "),_c('v-pagination',{attrs:{"length":_vm.$store.state.UserStore.totalPages,"total-visible":5},on:{"input":_vm.onPageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }