<template>
    <v-card >
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant.sync="mini"
            permanent
            app
        >
            <v-list-item class="px-2">
            <v-list-item-avatar>
                <v-img  link to="/" src="/arm.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-title link to="/">{{$store.state.AuthStore.user.firstName}} {{$store.state.AuthStore.user.lastName}}</v-list-item-title>

            <v-btn
                icon
                @click.stop="mini = !mini"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            </v-list-item>

            <v-divider></v-divider>
            <v-list dense>
            <v-list-item  v-for="link in links"  :key="link.name" link :to="link.routinglink" >
                <v-list-item-icon >
                <v-icon style="color:#EB8822">{{ link.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list>
            
                <template v-slot:append>
                    <div class="pa-2">
                    <v-btn    right fab x-small color="indigo white--text" style="margin:5px"><v-icon>mdi-cog-outline</v-icon></v-btn>
                    <v-btn   right fab x-small color="blue white--text" style="margin:5px"><v-icon>mdi-account-tie</v-icon></v-btn>
                    <v-btn    right fab x-small color="pink white--text" style="margin:5px"><v-icon>mdi-power</v-icon></v-btn>
                    </div>
                </template>
        </v-navigation-drawer>
    </v-card>  
</template>

<script>
export default {
    props:['mini'],
    data(){
        return{
            drawer:true,

    links:[
        {
            name:'Dashboard',
            icon:'mdi-view-dashboard',
            routinglink:'/latradts/questionReport'
        },
        {
            name:'Questions Bank',
            icon:'mdi-stack-overflow',
            routinglink:'/latradts/question_bank'
        },
    ],
        }
    }

}
</script>

<style>

</style>