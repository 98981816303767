import {apolloClient} from '@/vue-apollo'
import allExaminationSections from '../../graphql/Examinations/allExaminationSections.graphql'
import addNewExaminationSection from "../../graphql/Examinations/addNewExaminationSection.graphql";
import updateExaminationSection from "../../graphql/Examinations/updateExaminationSection.graphql";
import deleteExaminationSection from "../../graphql/Examinations/deleteExaminationSection.graphql";
import Vue from "vue";

export default {
    state: {
        allExaminationSections:[]
    },
    mutations: {
        addExaminationSections(state, data){
            state.allExaminationSections.push(data)
        },
        updateExaminationSections(state, data) {
            state.allExaminationSections.find(({sectionUniqueId}) => sectionUniqueId === data.sectionUniqueId).sectionName=data.sectionName
            state.allExaminationSections.find(({sectionUniqueId}) => sectionUniqueId === data.sectionUniqueId).sectionDescriptions=data.sectionDescriptions
        },
        clearExaminationSections(state){
            state.allExaminationSections=[]
        },
        deleteExaminationSections(state,data){
            state.allExaminationSections.splice(state.allExaminationSections.findIndex(({sectionUniqueId}) => sectionUniqueId === data.sectionUniqueId ),1)
        }
    },
    actions: {
        async LoadExaminationSections(context){
            context.commit('clearExaminationSections')

            apolloClient.query({
                query:allExaminationSections
            }).then((response)=>{
                response.data.allExaminationSections.edges.forEach(element=>{

                    let data={
                        key:element.node.primaryKey,
                        sectionUniqueId:element.node.sectionUniqueId,
                        sectionName:element.node.sectionName,
                        sectionDescriptions:element.node.sectionDesciptions,
                    }
                    console.log(data)
                    context.commit('addExaminationSections',data)
                })

            })
        },
        async addNewExaminationSections(context,data){
            apolloClient.mutate({
                mutation:addNewExaminationSection,
                variables:data
            }).then((response)=>{

                let data={
                    sectionUniqueId:response.data.addNewExaminationSection.newSection.sectionUniqueId,
                    sectionName:response.data.addNewExaminationSection.newSection.sectionName,
                    sectionDescriptions:response.data.addNewExaminationSection.newSection.sectionDesciptions,

                }
                context.commit('addExaminationSections',data)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Examination Section created Succesfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! An error Occured',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },
        async updateExaminationSection(context,data){
            console.log("Hii ni Action")
            apolloClient.mutate({
                mutation:updateExaminationSection,
                variables:data
            }).then((response)=>{
                let data={
                    sectionUniqueId:response.data.updateExaminationSection.updateSection.sectionUniqueId,
                    sectionName:response.data.updateExaminationSection.updateSection.sectionName,
                    sectionDescriptions:response.data.updateExaminationSection.updateSection.sectionDesciptions,
                }
                context.commit('updateExaminationSections',data)
                if(!response.errors){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Examination Section Updated Succesfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {

                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! An error Occured',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },
        async deleteExaminationSection(context,data){
            console.log("Hii ni Action")
            apolloClient.mutate({
                mutation:deleteExaminationSection,
                variables:data
            }).then((response)=>{
                let data={
                    sectionUniqueId:response.data.deleteExaminationSection.deleteSection.sectionUniqueId,
                    success:response.data.deleteExaminationSection.success,
                    code:response.data.deleteExaminationSection.code,
                }

                if(response.data.deleteExaminationSection.success){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Examination Section Deleted Succesfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });
                    context.commit('deleteExaminationSections',data)
                }else {

                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Error: '+response.data.deleteExaminationSection.code,
                        text: "Oops! an error Occurred.",
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },

    }
}